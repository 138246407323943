<template>
  <v-form ref="form">
    <default-form-layout>
      <template v-slot:title>
        <h2>{{ headerText }} Produtor</h2>
      </template>
      <template v-slot:body>
        <v-progress-linear v-show="loading" indeterminate />

        <!-- Tabs -->
        <v-tabs v-model="activeTab">
          <v-tab>Dados</v-tab>
          <v-tab v-if="id != null">Agregados</v-tab>
          <v-tab v-if="id != null">Documentos</v-tab>
          <v-tab v-if="id != null">Apiários</v-tab>
          <v-tab v-if="id != null">Média Produtiva</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <!-- Aba Dados -->
          <v-tab-item style="padding: 10px 0">
            <v-col>
              <!-- Linha 1 -->
              <v-row style="gap: 10px">
                <v-select
                  label="Tipo"
                  class="required"
                  color="primary"
                  item-value="id"
                  item-text="name"
                  v-model="register.type"
                  :items="types"
                  :rules="[formRules.required]"
                />

                <v-text-field
                  label="Nome"
                  class="required"
                  v-model="register.name"
                  :rules="[formRules.required]"
                />

                <v-text-field label="Código OESA" v-model="register.oesa" />

                <v-text-field
                  label="FIXO/MIG"
                  readonly
                  chips
                  deletable-chips
                  clearable
                  v-model="register.permanence"
                  :items="permanenceItems"
                  @change="getApiaryList"
                />

                <v-text-field label="Apelido" v-model="register.nick" />
              </v-row>

              <!-- Linha 2 -->
              <v-row style="gap: 10px">
                <v-text-field
                  label="Data de Nascimento"
                  type="date"
                  v-model="register.birth"
                />

                <v-text-field
                  label="Atualizado em"
                  type="date"
                  v-model="register.updated_at"
                  readonly
                />

                <v-text-field
                  label="CPF/CNPJ"
                  class="required"
                  v-model="register.cnpj"
                  :rules="[formRules.required, formRules.cpf]"
                  @input="register.cnpj = formatCpf(register.cnpj)"
                />

                <v-text-field
                  label="E-mail"
                  type="email"
                  v-model="register.email"
                />

                <v-text-field label="Telefone" v-model="register.phone" />
              </v-row>

              <!-- Linha 3 -->
              <v-row style="gap: 10px">
                <v-text-field
                  label="Insc. de Produtor Rural"
                  v-model="register.inscription"
                />

                <v-text-field
                  label="Endereço"
                  v-model="register.address_description"
                />

                <v-text-field
                  label="Número"
                  v-model="register.address_number"
                />

                <v-text-field label="CEP" v-model="register.cep" />
              </v-row>

              <!-- Linha 4 -->
              <v-row style="gap: 10px">
                <EstadosComponent
                  :class="'required'"
                  :value="register.uf"
                  @updateEstado="updateListCidades"
                />

                <CidadesComponent
                  :class="'required'"
                  :city="register.city_name"
                  :value="register.uf"
                  @updateCidade="updateSelectedCidade"
                />

                <v-select
                  chips
                  clearable
                  deletable-chips
                  label="Grupo"
                  color="primary"
                  item-value="id"
                  item-text="name"
                  v-model="register.apiary_group_id"
                  :items="apiaryGroups"
                />

                <v-switch
                  color="#b9041c"
                  v-model="register.active"
                  :label="register.active ? 'Ativo' : 'Inativo'"
                />
              </v-row>
            </v-col>
          </v-tab-item>

          <!-- Agregados -->

          <v-tab-item>
            <template>
              <v-row row justify-space-between>
                <v-col xs="7" md="5">
                  <v-text-field
                    v-model="aggregateFilter.term"
                    ref="search"
                    label="Faça a sua busca"
                    clear-icon="close"
                    clearable
                    append-icon="search"
                    @click:append="getListAggregates"
                    @keyup="getListAggregates"
                    @keyup.enter="getListAggregates"
                  />
                </v-col>

                <v-col></v-col>
                <!-- Botão de Incluir -->
                <v-col class="text-xs-right d-flex">
                  <v-btn
                    class="flex-one ml-auto"
                    color="primary"
                    large
                    @click="openDialogAggregate()"
                  >
                    + Incluir
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Modal de Incluir -->
              <v-dialog v-model="dialogAggregate" max-width="600px">
                <v-card>
                  <v-card-title class="headline">Criar Agregado</v-card-title>

                  <v-card-text>
                    <v-form ref="form">
                      <v-row>
                        <!-- Nome do Agregado -->
                        <v-col xs="12" class="pa-3">
                          <v-text-field
                            v-model="aggregate.name"
                            label="Agregado"
                            required
                            :rules="[formRules.required]"
                          ></v-text-field>
                        </v-col>

                        <!-- CPF -->
                        <v-col xs="12" class="pa-3">
                          <v-text-field
                            v-model="aggregate.cpf"
                            label="CPF"
                            required
                            :rules="[formRules.required, formRules.cpf]"
                            @input="aggregate.cpf = formatCpf(aggregate.cpf)"
                          ></v-text-field>
                        </v-col>

                        <!-- Grau -->
                        <v-col xs="12" class="pa-3">
                          <v-select
                            v-model="aggregate.type"
                            label="Grau"
                            :items="typesAggregate"
                            item-text="Text"
                            item-value="value"
                            required
                            :rules="[formRules.required]"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="saveAggregate"
                      >Salvar</v-btn
                    >
                    <v-btn color="error" text @click="dialogAggregate = false"
                      >Cancelar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-data-table
                :headers="tableAggregates.headers"
                :items="tableAggregates.items"
                item-value="name"
              >
                <template v-slot:[`item.cpf`]="{ item }">
                  <span>{{ formatCpf(item.cpf) }}</span>
                </template>
                :loading="tableAggregates.loading" class="elevation-1"
                hide-default-footer >
                <template v-slot:[`item.actions`]="{ item, index }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="editDialogAggregate(item, index)"
                        icon
                        v-on="on"
                      >
                        <v-icon color="#707070">edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="removeAggregate(item, index)"
                        icon
                        v-on="on"
                      >
                        <v-icon color="#707070">delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Excluir</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <div>
                <v-card class="pa-3">
                  <v-row wrap>
                    <v-col xs="12" md="12" class="text-xs-center">
                      <v-pagination
                        v-model="tableAggregates.pagination.page"
                        :length="tableAggregates.pagination.pages"
                        v-bind:disabled="tableAggregates.disabled"
                        :total-visible="tableAggregates.pagination.totalVisible"
                        prev-icon="arrow_left"
                        next-icon="arrow_right"
                        primary
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </template>
          </v-tab-item>
          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn :to="`/suppliers/edit/${item.id}`" icon v-on="on">
                  <v-icon color="#707070">edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="remove(item.id)" icon v-on="on">
                  <v-icon color="#707070">clear</v-icon>
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template> -->

          <!-- Documentos -->
          <v-tab-item>
            <template>
              <v-data-table
                :headers="tableDocs.headers"
                :items="tableDocs.items"
                :loading="tableDocs.loading"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip dark :color="formStatusColor(item)">
                    {{ formStatus(item) }}
                  </v-chip>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ $functions.formatDate(item.created_at) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="printFilled(item)" icon v-on="on">
                        <v-icon color="#707070">download</v-icon>
                      </v-btn>
                    </template>
                    <span>Baixar Template</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        @click="showDoc(item)"
                        class="mx-1"
                        icon
                        text
                        v-on="on"
                      >
                        <v-icon color="#707070">visibility</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver projeto</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        @click="removeDoc(item.import_key)"
                        class="mx-1"
                        icon
                        text
                        v-on="on"
                      >
                        <v-icon color="#707070">delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Excluir</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <div>
                <v-card class="pa-3">
                  <v-row wrap>
                    <v-col xs="12" md="12" class="text-xs-center">
                      <v-pagination
                        v-model="tableDocs.pagination.page"
                        :length="tableDocs.pagination.pages"
                        v-bind:disabled="tableDocs.disabled"
                        :total-visible="tableDocs.pagination.totalVisible"
                        prev-icon="arrow_left"
                        next-icon="arrow_right"
                        primary
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </template>
          </v-tab-item>

          <!-- Aba Apiários -->
          <v-tab-item style="padding: 10px 0">
            <template>
              <v-row>
                <v-col xs="7" md="4">
                  <v-text-field
                    v-model="filter.term"
                    ref="search"
                    label="Faça a sua busca"
                    clear-icon="close"
                    clearable
                    append-icon="search"
                    @click:append="getApiaryList"
                    @keyup="getApiaryList"
                    @keyup.enter="getApiaryList"
                  />
                </v-col>
                <v-col xs="3" md="4" class="text-xs-left">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        large
                        color="normal"
                        v-on="on"
                        @click="toggleFilters"
                        v-shortkey="['ctrl', 'f']"
                      >
                        <v-icon>filter_list</v-icon>
                      </v-btn>
                    </template>
                    <span>Clique para selecionar os filtros</span>
                  </v-tooltip>
                </v-col>
                <v-col></v-col>

                <v-col class="text-xs-right d-flex">
                  <v-btn
                    large
                    class="flex-one ml-auto"
                    color="primary"
                    @click="openModal"
                  >
                    Gerar PDF
                  </v-btn>
                </v-col>
                <v-dialog v-model="showModal" persistent max-width="400px">
                  <v-card>
                    <v-card-title class="headline justify-space-between">
                      Deseja gerar o Relatório?
                      <v-btn icon @click="closeModal">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <p class="text-body-1 mb-4">
                        Selecione o relatório que deseja gerar
                      </p>
                      <v-switch
                        v-model="options.apiaryList"
                        label="Lista de Apiários"
                        class="ma-0"
                      />
                      <v-switch
                        v-model="options.mapAssessment"
                        label="Mapa de Avaliação"
                        class="ma-0"
                      />
                    </v-card-text>

                    <v-card-actions>
                      <v-row class="m-0 p-0" no-gutters>
                        <v-col class="px-1">
                          <v-btn
                            block
                            medium
                            text
                            color="red"
                            @click="closeModal"
                          >
                            Cancelar
                          </v-btn>
                        </v-col>
                        <v-col class="px-1">
                          <v-btn
                            block
                            medium
                            color="primary"
                            @click="generatePdfApiary"
                          >
                            Gerar Relatório
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <v-expand-transition>
                <v-row row wrap v-show="expandFilters">
                  <v-col md="12">
                    <v-card class="elevation-1 pa-3 mb-4">
                      <v-row row>
                        <v-col xs="12">
                          <v-label>
                            Selecione os campos para fazer a busca
                          </v-label>
                        </v-col>
                      </v-row>

                      <v-row row wrap>
                        <v-col xs="12" md="3" pa-3>
                          <v-select
                            chips
                            deletable-chips
                            clearable
                            v-model="filter.active"
                            :items="activeItems"
                            label="Ativo"
                            @change="getApiaryList"
                          />
                        </v-col>

                        <v-col xs="12" md="3" pa-3>
                          <v-select
                            chips
                            deletable-chips
                            clearable
                            v-model="filter.flowery"
                            :items="floweries"
                            item-text="name"
                            item-value="id"
                            label="Florada"
                            @change="getApiaryList"
                          />
                        </v-col>

                        <v-col xs="12" md="3" pa-3>
                          <v-select
                            chips
                            deletable-chips
                            clearable
                            v-model="filter.permanence"
                            :items="permanenceItems"
                            label="Permanência"
                            @change="getApiaryList"
                          />
                        </v-col>

                        <v-col xs="12" md="3" pa-3>
                          <v-select
                            chips
                            deletable-chips
                            clearable
                            v-model="filter.quality"
                            :items="qualityItems"
                            label="Qualidade"
                            @change="getApiaryList"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expand-transition>
              <v-data-table
                :headers="table.headers"
                :items="table.items"
                :loading="table.loading"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:[`item.active`]="{ item }">
                  <v-chip
                    v-if="item.active == 1"
                    color="green"
                    text-color="white"
                    >Ativo</v-chip
                  >
                  <v-chip v-else color="#5e636b" text-color="white"
                    >Inativo</v-chip
                  >
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="printApiaryMap(item)" icon v-on="on">
                        <v-icon color="#707070">download</v-icon>
                      </v-btn>
                    </template>
                    <span>Baixar Mapa de Avaliação</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        @click="openDialogDetails(item)"
                        class="mx-0"
                        icon
                        text
                        v-on="on"
                      >
                        <v-icon color="#707070">visibility</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver detalhes</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn :to="`/apiaries/edit/${item.id}`" icon v-on="on">
                        <v-icon color="#707070">edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        @click="maps(item)"
                        class="mx-0"
                        icon
                        text
                        v-on="on"
                      >
                        <v-icon color="#707070">gps_fixed</v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar no mapa</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        @click="removeApiary(item.id)"
                        class="mx-0"
                        icon
                        text
                        v-on="on"
                      >
                        <v-icon color="#707070">delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Excluir</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <div>
                <v-card class="pa-3">
                  <v-row wrap>
                    <v-col xs="12" md="12" class="text-xs-center">
                      <v-pagination
                        v-model="table.pagination.page"
                        :length="table.pagination.pages"
                        v-bind:disabled="table.disabled"
                        :total-visible="table.pagination.totalVisible"
                        prev-icon="arrow_left"
                        next-icon="arrow_right"
                        primary
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </template>
          </v-tab-item>

          <v-tab-item>
            <v-col>
              <MediaProdutiva
                :register="register"
                @save-data="handleSaveData"
              />
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </template>

      <template v-slot:actions>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn color="error" text @click="cancel">Cancelar</v-btn>
      </template>
    </default-form-layout>

    <!-- maps dialog -->
    <v-dialog v-model="dialogMaps" fullscreen hide-overlay persistent>
      <v-card>
        <v-toolbar
          style="position: absolute; top: 0; z-index: 9999; width: 100%"
          dark
          color="primary"
        >
          <v-btn icon dark @click="this.closeDialogMaps">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ apiaryMaps ? apiaryMaps.name : "" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <MyMap ref="myMap"></MyMap>
      </v-card>
    </v-dialog>
    <!-- fim maps dialog -->

    <!-- detail dialog-->
    <v-dialog v-model="dialogDetails" width="1000">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="this.closeDialogDetails">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Apiário {{ selectedItem.name }} -
            {{ selectedItem.active == 1 ? "Ativo" : "Inativo" }}
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="maps(selectedItem), (dialogDetails = !dialogDetails)"
                  icon
                  text
                  dark
                  v-on="on"
                >
                  <v-icon>gps_fixed</v-icon>
                </v-btn>
              </template>
              <span>Visualizar no mapa</span>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text style="padding-top: 10px">
          <v-row grid-list-md row wrap>
            <v-col md="4" pt-2>
              <v-label>Grupo de apiários</v-label>
              <span class="d-block font-weight-bold">
                {{
                  selectedItem.apiaryGroup ? selectedItem.apiaryGroup.name : "-"
                }}
              </span>
            </v-col>

            <v-col md="4" pt-2>
              <v-label>Florada</v-label>
              <span class="d-block font-weight-bold">
                {{
                  selectedItem.floweries
                    ? getFloweryNames(selectedItem.floweries)
                    : "-"
                }}
              </span>
            </v-col>

            <!-- <v-col md="4" pt-4>
              <v-label>Localidade</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.locale }}
              </span>
            </v-col> -->
            <!-- 
            <v-col md="4" pt-4>
              <v-label>Cidade/UF</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.city }}/{{ selectedItem.uf }}
              </span>
            </v-col> -->

            <v-col md="4" pt-4>
              <v-label>Quantidade de colmeias</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.hive_count }}
              </span>
            </v-col>

            <v-col md="4" pt-4>
              <v-label>Qualidade</v-label>
              <span class="d-block font-weight-bold">
                {{ getQualityName(selectedItem.quality) }}
              </span>
            </v-col>

            <!-- <v-col md="4" pt-4>
              <v-label>Permanência</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.permanence }}
              </span>
            </v-col> -->

            <v-col md4 pt-4 v-if="selectedItem.permanence === 'Migratório'">
              <v-label>Meses</v-label>
              <span class="d-block font-weight-bold">
                {{
                  selectedItem.months ? getMonthNames(selectedItem.months) : "-"
                }}
              </span>
            </v-col>

            <v-col
              md="4"
              pt-4
              v-if="selectedItem.latitude || selectedItem.longitude"
            >
              <v-label>Latitude/Longitude</v-label>
              <div class="coord">
                <v-tooltip transition="fab-transition" top>
                  <v-chip
                    slot="activator"
                    small
                    label
                    color="#0ebcff"
                    text-color="white"
                  >
                    {{ getLatLngValues(selectedItem.latitude, "lat") }}
                  </v-chip>
                  <span>
                    <ul v-for="lat in latitudeOptions" :key="lat.type">
                      <li>
                        {{ `${lat.type}: ${convertCoordinates(lat.newValue)}` }}
                      </li>
                    </ul>
                  </span>
                </v-tooltip>
                <v-tooltip transition="fab-transition" top>
                  <v-chip
                    slot="activator"
                    small
                    label
                    color="#d4a041"
                    text-color="white"
                  >
                    {{ getLatLngValues(selectedItem.longitude, "lng") }}
                  </v-chip>
                  <span>
                    <ul v-for="lng in longitudeOptions" :key="lng.type">
                      <li>
                        {{ `${lng.type}: ${convertCoordinates(lng.newValue)}` }}
                      </li>
                    </ul>
                  </span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDetails = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import EFormResponseStatus from "@/modules/forms/domain/enums/FormResponseStatusEnum";
import DefaultFormLayout from "@/shared/components/layout/DefaultFormLayout.vue";
import CidadesComponent from "../estadosCidades/CidadesComponent";
import EstadosComponent from "../estadosCidades/EstadosComponent";
import MyMap from "../Maps/MyMap";
import MediaProdutiva from "./components/MediaProdutiva.vue";

export default {
  components: {
    DefaultFormLayout,
    EstadosComponent,
    CidadesComponent,
    MyMap,
    MediaProdutiva,
  },
  props: {
    supplier: { type: String, default: null },
    apiaryGroup: { type: Number, default: null },
    apiaries: { type: [], default: null },
  },
  name: "RegisterSupplier",
  data() {
    return {
      id: null,
      showModal: false,
      dialogMaps: false,
      dialogDetails: false,
      dialogAggregate: false,
      dialogAggregateEdit: false,
      activeTab: 0,
      apiaryMaps: null,
      selectedItem: {},
      selectedPermanence: {},
      headerText: "Novo",
      expandFilters: false,
      formRules: {
        required: (v) => !!v || "Campo obrigatório!",
        cpf: (value) => this.validateCpfCnpj(value) || "CPF inválido",
      },
      register: {
        type: "PRODUTOR PRINCIPAL",
        active: true,
        aggregates: [],
        saveAggregate: false,
        averageProductivity: [],
      },
      loading: false,
      types: ["PRODUTOR PRINCIPAL"],
      apiaryGroups: [],
      filter: {},
      aggregateFilter: {},
      floweries: [],
      typesAggregate: [
        {
          value: "Familiar",
          Text: "Familiar",
        },
        {
          value: "Sociedade",
          Text: "Sociedade",
        },
      ],
      tableAggregates: {
        headers: [
          {
            text: "Agregado",
            align: "left",
            value: "name",
            sortable: false,
          },
          {
            text: "CPF",
            align: "left",
            value: "cpf",
            sortable: false,
          },
          {
            text: "Grau",
            align: "left",
            value: "type",
            sortable: false,
          },
          {
            text: "",
            width: "190px",
            align: "right",
            value: "actions",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        pagination: {
          page: 1,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      aggregateToEdit: -1,
      registerAggregate: [],
      activeItems: [
        { value: "1", text: "Sim" },
        { value: "0", text: "Não" },
      ],
      permanenceItems: [
        { value: "Fixo", text: "Fixo" },
        { value: "Migratório", text: "Migratório" },
      ],
      qualityItems: [
        { value: 1, text: "Convencional" },
        { value: 2, text: "Orgânico" },
      ],
      tableDocs: {
        headers: [
          {
            text: "Status",
            align: "left",
            value: "status",
            sortable: false,
          },
          {
            text: "Documento",
            align: "left",
            value: "form.name",
            sortable: false,
          },
          {
            text: "Versão",
            align: "left",
            value: "form.version_number",
            sortable: false,
          },
          {
            text: "Projeto",
            align: "left",
            value: "project.name",
            sortable: false,
          },
          {
            text: "Data de preenchimento",
            align: "left",
            value: "created_at",
            sortable: false,
          },
          {
            text: "",
            width: "190px",
            align: "right",
            value: "actions",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        pagination: {
          page: 1,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      table: {
        headers: [
          {
            text: "Status",
            align: "left",
            value: "active",
            sortable: false,
          },
          {
            text: "Apiário",
            align: "left",
            value: "name",
          },
          // {
          //   text: "Cidade/UF",
          //   align: "left",
          //   value: "city",
          // },
          // {
          //   text: "Localidade",
          //   width: "200px",
          //   align: "left",
          //   value: "locale",
          // },
          // {
          //   text: "Permanência",
          //   align: "center",
          //   value: "permanence",
          // },
          {
            text: "Colmeias Produtivas",
            align: "center",
            value: "hive_count",
            sortable: false,
          },
          {
            text: "",
            width: "190px",
            align: "right",
            value: "actions",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        pagination: {
          page: 1,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      aggregate: {
        type: "",
        name: "",
        cpf: "",
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
      options: {
        apiaryList: false,
        mapAssessment: false,
      },
    };
  },

  async mounted() {
    try {
      this.loading = true;

      this.id = this.$route.params.id;

      if (this.id) {
        this.headerText = "Alterar";
        await Promise.all([
          this.getData(),
          this.getDataAggregate(),
          this.getApiaryList(),
          this.getFloweries(),
        ]);
      }

      await this.getSuppliers();
      await this.getSystems();

      await this.getApiaryGroups();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    showDoc(item) {
      this.$router.push(`/project/edit/${item.project_id}/data`);
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    validateCpfCnpj(cpfCnpj) {
      if (!cpfCnpj) return false;

      const cleanValue = cpfCnpj.replace(/\D/g, "");

      if (cleanValue.length === 11) {
        if (/^(\d)\1+$/.test(cleanValue)) return false;

        let sum = 0;
        let remainder;

        // Primeiro dígito verificador
        for (let i = 1; i <= 9; i++) {
          sum += parseInt(cleanValue.substring(i - 1, i)) * (11 - i);
        }

        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cleanValue.substring(9, 10))) return false;

        // Segundo dígito verificador
        sum = 0;
        for (let i = 1; i <= 10; i++) {
          sum += parseInt(cleanValue.substring(i - 1, i)) * (12 - i);
        }

        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;

        return remainder === parseInt(cleanValue.substring(10, 11));
      }

      // Validação de CNPJ
      if (cleanValue.length === 14) {
        if (/^(\d)\1+$/.test(cleanValue)) return false; // Verifica se todos os dígitos são iguais

        const validateDigits = (baseLength) => {
          let sum = 0;
          const weights =
            baseLength === 12
              ? [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
              : [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

          for (let i = 0; i < baseLength; i++) {
            sum += parseInt(cleanValue.charAt(i)) * weights[i];
          }

          const remainder = sum % 11;
          const checkDigit = remainder < 2 ? 0 : 11 - remainder;

          return checkDigit === parseInt(cleanValue.charAt(baseLength));
        };

        return validateDigits(12) && validateDigits(13);
      }

      return false;
    },

    handleSaveData(data) {
      this.register.averageProductivity = data;
    },

    formatCpf(value) {
      const cleaned = value.replace(/\D/g, "");

      if (cleaned.length <= 11) {
        return cleaned
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{2})$/, "$1-$2");
      }

      return value;
    },

    formStatus(item) {
      return EFormResponseStatus.serializedStatus(item.status);
    },

    async removeAggregate(item, index) {
      this.tableAggregates.items.splice(index, 1);
      this.snackbar.color = "success";
      this.snackbar.message = "Item removido com sucesso!";
      this.$root.$SnackBar.show(this.snackbar);

      await this.minamelServices
        .SupplierServiceV2()
        .deleteAggregate(item.import_key);

      return;
    },

    async removeApiary(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices.ApiaryServiceV2().destroy(id);

      if (res) {
        this.table.pagination.page = 1;
        await this.getApiaryList();
      }
    },

    async alertConfirm(ask, type) {
      let response = await this.$swal.fire({
        title: `${ask}`,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });

      if (response.value) {
        return true;
      }

      return false;
    },

    async removeDoc(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices
        .ProjectFormResponseService()
        .destroy(id);

      if (res) {
        await this.getData();
      }
    },

    editDialogAggregate(item, index) {
      this.aggregateToEdit = index;
      this.dialogAggregate = true;
      this.dialogAggregateEdit = true;

      this.aggregate = item;
    },

    openDialogAggregate() {
      this.aggregateToEdit = -1;
      this.dialogAggregateEdit = false;
      this.dialogAggregate = true;
      this.aggregate = { name: "", cpf: "", type: "" };
    },

    formStatusColor(item) {
      switch (item.status) {
        case EFormResponseStatus.ANSWERED:
          return "warning";
        case EFormResponseStatus.IN_PROGRESS:
          return "yellow darken-2";
        case EFormResponseStatus.APPROVED:
          return "success";
        case EFormResponseStatus.DISAPPROVED:
          return "error;";
        default:
          return "grey";
      }
    },

    async printApiaryMap(item) {
      try {
        const params = {
          importKey: item.import_key,
        };
        const response = await this.minamelServices
          .ApiaryServiceV2()
          .printApiaryMap(params);

        const fileName = `mapa_de_avaliação_${item.name}.pdf`;

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message =
          "Ocorreu um erro ao realizar o download do mapa de apiários";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async printFilled(item) {
      try {
        const response = await this.minamelServices
          .DocumentTemplateService()
          .printFilled(item.import_key);

        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `${item.form.name}.pdf`);

        document.body.appendChild(link);

        link.click();
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message =
          "Ocorreu um erro ao realizar o download do formulário";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async getData() {
      const response = await this.minamelServices
        .SupplierServiceV2()
        .show(this.id);

      this.register = response.data;

      // if (!this.register.type) {
      this.register.type = "PRODUTOR PRINCIPAL";
      // }

      this.getDocs();
      this.getListAggregates(this.register.aggregate_id);
    },

    async getDocs() {
      try {
        this.tableDocs.loading = true;

        const params = {
          page: this.tableDocs.pagination.page,
          perPage: this.tableDocs.pagination.perPage,
          importKey: this.register.import_key,
        };

        const response = await this.minamelServices
          .ProjectFormResponseService()
          .getByGroup(this.register.apiary_group_id, params);

        this.tableDocs.items = response.data;
        this.tableDocs.pagination.pages = response.meta.last_page;
        this.tableDocs.loading = false;
      } catch (err) {
        this.tableDocs.loading = false;
        console.log(err);
      }
    },

    async getAggregates() {
      try {
        this.tableAggregates.loading = true;

        const params = {
          term: this.aggregateFilter.term ? this.aggregateFilter.term : "",
          page: this.tableAggregates.pagination.page,
          perPage: this.tableAggregates.pagination.perPage,
          active: this.filter.active ? this.filter.active : "",
        };

        const res = await this.minamelServices
          .SupplierServiceV2()
          .getAggregates(params);

        this.tableAggregates.pagination.pages = res.meta.last_page;
        this.tableAggregates.loading = false;
      } catch (err) {
        this.tableAggregates.loading = false;
      }
    },

    async getListAggregates() {
      try {
        this.tableAggregates.loading = true;

        const params = {
          id: this.id,
          term: this.aggregateFilter.term ? this.aggregateFilter.term : "",
          page: this.tableAggregates.pagination.page,
          perPage: this.tableAggregates.pagination.perPage,
          // aggregates: true,
        };

        const res = await this.minamelServices
          .SupplierServiceV2()
          .getAggregateById(params);

        if (res) {
          res.forEach((item) => {
            if (item.type === "2") {
              item.type = "Sociedade";
            } else if (item.type === "1") {
              item.type = "Familiar";
            }
          });
        }

        this.tableAggregates.items = res;
        this.tableAggregates.pagination.pages = res.meta.last_page;
        this.tableAggregates.loading = false;
      } catch (err) {
        this.tableAggregates.loading = false;
      }
    },
    async getApiaryList() {
      try {
        this.table.loading = true;

        const params = {
          supplierId: this.id,
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          term: this.filter.term ?? "",
          active: this.filter.active ?? "",
          flowery: this.filter.flowery ?? "",
          quality: this.filter.quality ?? "",
          permanence: this.filter.permanence ?? "",
        };
        const response = await this.minamelServices
          .ApiaryServiceV2()
          .getListByProducer(params);

        if (response.data && response.data.length > 0) {
          const migratoryApiary = response.data.find(
            (apiary) => apiary.permanence === "Migratório"
          );

          const fixedApiary = response.data.find(
            (apiary) => apiary.permanence === "Fixo"
          );

          if (migratoryApiary) {
            this.register.permanence = "Migratório";
          } else if (fixedApiary) {
            this.register.permanence = "Fixo";
          }
        }

        this.table.items = response.data;
        this.table.pagination.pages = response.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    async getApiaryGroups() {
      const response = await this.minamelServices
        .ApiaryGroupServiceV2()
        .getAll({ active: 1 });

      this.apiaryGroups = response.data;
    },

    async getFloweries() {
      const response = await this.minamelServices
        .FloweryServiceV2()
        .get({ active: 1 });

      this.floweries = response.data;
    },

    async save() {
      const formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      this.register.saveAggregate = false;

      if (this.id) {
        this.update();
        return;
      }

      this.create();
    },

    async create() {
      try {
        const response = await this.minamelServices
          .SupplierServiceV2()
          .store(this.register);

        if (response.message) {
          this.$swal.fire({
            title: "Atenção",
            text: response.message,
            type: "info",
            showCancelButton: false,
            allowOutsieClick: false,
            width: 500,
          });
          return;
        }
        this.register.saveAggregate = false;

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Produtor incluído com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.back();
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message =
          err.response.data ?? "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const response = await this.minamelServices
          .SupplierServiceV2()
          .update(this.id, this.register);

        if (response.message) {
          this.$swal.fire({
            title: "Atenção",
            text: response.message,
            type: "info",
            showCancelButton: false,
            allowOutsieClick: false,
            width: 500,
          });
          return;
        }

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Produtor alterado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          if (this.dialogAggregate) {
            this.dialogAggregate = false;
            await this.getListAggregates();
          } else {
            this.$router.back();
          }
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar a alteração.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async getSuppliers() {
      const response = await this.minamelServices
        .SupplierServiceV2()
        .getAll({});

      this.suppliers = response.data;
    },

    async getSystems() {
      const response = await this.minamelServices.SystemServiceV2().getAll();

      this.systems = response.data;
    },

    async getDataAggregate() {
      this.loading = true;

      const response = await this.minamelServices
        .SupplierServiceV2()
        .show(this.id);

      this.register.aggregates = response.data;
    },

    async saveAggregate() {
      const formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      if (this.dialogAggregateEdit && this.aggregateToEdit !== -1) {
        this.registerAggregate[this.aggregateToEdit] = this.aggregate;
      } else {
        this.registerAggregate.push(this.aggregate);
      }

      this.register.saveAggregate = true;

      this.register.aggregates = this.registerAggregate;
      if (this.id) {
        await this.updateAggregate();
      }
    },

    async updateAggregate() {
      try {
        const response = await this.minamelServices
          .SupplierServiceV2()
          .updateAggregate(this.id, this.aggregate);

        if (response.message) {
          this.$swal.fire({
            title: "Atenção",
            text: response.message,
            type: "info",
            showCancelButton: false,
            allowOutsieClick: false,
            width: 500,
          });
          return;
        }

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Produtor alterado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          if (this.dialogAggregate) {
            this.dialogAggregate = false;
            await this.getListAggregates();
          } else {
            this.$router.back();
          }
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar a alteração.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async generatePdfApiary() {
      try {
        this.closeModal();

        const params = {
          supplierImportKey: this.register.import_key,
          apiaryList: this.options.apiaryList,
          mapAssessment: this.options.mapAssessment,
        };

        const fileName = `${this.options.apiaryList ? "lista_de_apiario" : ""}${
          this.options.mapAssessment ? "_mapa_de_avaliacao" : ""
        }.pdf`;

        const res = await this.minamelServices
          .ApiaryServiceV2()
          .generatePdfApiary(params);

        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        console.log("PDF gerado e baixado com sucesso!");
      } catch (err) {
        console.error("Erro ao gerar o PDF:", err);
        this.$swal.fire({
          allowOutsideClick: true,
          title: "Erro",
          text: "Erro ao tentar gerar o relatório.",
          confirmButtonColor: "#b9041c",
          confirmButtonText: "Voltar",
          icon: "error",
        });
      }
    },

    verifyValidAggregate() {
      const supplier = this.suppliers.find((obj) => {
        return obj.name === this.aggregates.name;
      });

      const find = this.aggregates.find((obj) => {
        return obj === supplier.id;
      });

      if (find) {
        return true;
      }
      return false;
    },

    updateSelectedCidade(value) {
      this.register.cityName = value;
    },

    updateListCidades(value) {
      this.$set(this.register, "uf", value);

      if (!value) this.updateSelectedCidade(null);
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },

    openDialogDetails(item) {
      this.selectedItem = item;

      const apGroup = this.apiaryGroups.filter(
        (ap) => ap.id === item.apiary_group_id
      );

      if (apGroup) this.selectedItem.apiaryGroup = apGroup[0];

      this.dialogDetails = true;
    },

    closeDialogDetails() {
      this.dialogDetails = false;
    },

    convertCoordinates(value) {
      if (!value) {
        return "";
      }

      const arr = value.split(".");

      if (!arr || !arr[0] || !arr[1]) {
        return "";
      }

      return `${arr[0]}.${arr[1].substring(0, 2)}°`;
    },

    getMonthNames(months) {
      months = months.split(",");
      let arr = [
        { id: "01", name: "Janeiro" },
        { id: "02", name: "Fevereiro" },
        { id: "03", name: "Março" },
        { id: "04", name: "Abril" },
        { id: "05", name: "Maio" },
        { id: "06", name: "Junho" },
        { id: "07", name: "Julho" },
        { id: "08", name: "Agosto" },
        { id: "09", name: "Setembro" },
        { id: "10", name: "Outubro" },
        { id: "11", name: "Novembro" },
        { id: "12", name: "Dezembro" },
      ];
      if (months) {
        return arr
          .filter((month) => {
            return months.some((mes) => {
              return mes === month.id;
            })
              ? month.name
              : null;
          })
          .map((month) => {
            return month.name;
          })
          .join(", ");
      }
    },

    getFloweryNames(floweries) {
      let data = "";
      let cont = 1;

      floweries.forEach((item) => {
        data += item.name;

        if (floweries.length !== cont) {
          data += ", ";
          cont += 1;
        }
      });

      return data;
    },

    getQualityName(value) {
      if (value === 1) return "Convencional";

      if (value === 2) return "Orgânico";

      return "-";
    },

    getLatLngValues(value) {
      return this.convertCoordinates(value);
    },

    async maps(item) {
      item.supplier_name = this.register.name;
      this.apiaryMaps = item;
      const observations = item.observations;
      const blockMap = true;
      this.dialogMaps = true;

      await this.$functions.delay(500);

      await this.$refs.myMap.loadDataMap(
        this.apiaryMaps,
        observations,
        blockMap
      );

      setTimeout(() => {
        this.openDialogMaps();
      }, 400);
    },

    async openDialogMaps() {
      this.dialogMaps = true;

      await this.$functions.delay(500);

      document.querySelector("html").style.overflow = "hidden";
    },

    getStaticMapUrl(apiaryMaps) {
      const apiKey = this.CONFIG.VUE_APP_API_KEY_GOOGLE_MAPS;
      const latitude = parseFloat(apiaryMaps.latitude);
      const longitude = parseFloat(apiaryMaps.longitude);
      const zoom = 12;
      const size = "800x600";
      const mapType = "satellite";

      const markers = this.$refs.myMap.getMarkers().observations || [];
      const circles = this.$refs.myMap.getMarkers().circles;

      const mainMarker = `markers=color:red|label:P|${latitude},${longitude}`;

      const markersParam = markers
        .map((marker, index) => {
          const label = String.fromCharCode(65 + index);
          return `markers=color:blue|label:${label}|${marker.latitude},${marker.longitude}`;
        })
        .join("&");

      const style = "feature:all|element:labels|visibility:off";

      if (!circles || circles.length === 0) {
        return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=${size}&style=${style}&maptype=${mapType}&${mainMarker}&${markersParam}&key=${apiKey}`;
      }

      const numPoints = 60;
      const earthRadius = 6378;

      const circlesPaths = circles.map((circle) => {
        const radiusKm = circle.radius / 1000;
        const circleColor = circle.color.replace("#", "0x");

        const points = [];
        for (let i = 0; i < numPoints; i++) {
          const angle = (i / numPoints) * 2 * Math.PI;

          const latOffset =
            (radiusKm / earthRadius) * (180 / Math.PI) * Math.sin(angle);
          const lonOffset =
            ((radiusKm / earthRadius) * (180 / Math.PI) * Math.cos(angle)) /
            Math.cos(latitude * (Math.PI / 180));

          const pointLat = latitude + latOffset;
          const pointLon = longitude + lonOffset;
          points.push(`${pointLat.toFixed(6)},${pointLon.toFixed(6)}`);
        }

        points.push(points[0]);

        return `path=color:${circleColor}|weight:5|${points.join("|")}`;
      });

      return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=${size}&style=${style}&maptype=${mapType}&${mainMarker}&${markersParam}&${circlesPaths.join(
        "&"
      )}&key=${apiKey}`;
    },

    async convertImageToBase64(imageUrl) {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error("Erro ao converter imagem para Base64:", error);
        return null;
      }
    },

    async closeDialogMaps() {
      // this.dialogMaps = false;

      let marker = this.$refs.myMap.getMarkers().marker;
      let remarks = this.$refs.myMap.getMarkers().observations;
      let description = this.$refs.myMap.getFields().description;
      let risks = this.$refs.myMap.getFields().risks;

      if (marker || risks) {
        marker.id = this.apiaryMaps.import_key;

        const riskNames = risks.map((risk) => risk.name);

        // await this.$refs.myMap.updateApiaryLocation(marker);

        await this.$refs.myMap.updateApiaryRemark(
          this.apiaryMaps.import_key,
          remarks
        );

        await this.$refs.myMap.updateApiaryFields(
          this.apiaryMaps.import_key,
          description,
          riskNames
        );
      }

      const staticMapUrl = await this.getStaticMapUrl(this.apiaryMaps);

      // const mapElement = this.$refs.myMap.$el.children[0];

      const imageBase64 = await this.convertImageToBase64(staticMapUrl);

      // const imageBase64 = canvas.toDataURL("image/png");

      await this.minamelServices
        .ApiaryServiceV2()
        .downloadImage(this.apiaryMaps.import_key, imageBase64);

      this.dialogMaps = false;

      document.querySelector("html").style.overflow = null;

      this.getData();
    },

    cancel() {
      this.$router.back();
    },
  },

  watch: {
    "table.pagination.page": function () {
      this.getApiaryList();
    },

    "tableDocs.pagination.page": function () {
      this.getDocs();
    },

    "tableAggregates.pagination.page": function () {
      this.getListAggregates();
    },

    register: function () {
      if (this.register.birth) {
        this.register.birth = this.register.birth.split("T")[0];
      }

      if (this.register.updated_at) {
        this.register.updated_at = this.register.updated_at.split("T")[0];
      }
    },
  },
};
</script>

<style>
.coord {
  display: flex;
  gap: 10px;
}
</style>
