<template>
  <v-container class="media-produtiva" max-width="800px">
    <v-row dense>
      <v-col cols="6">
        <v-text-field
          label="Número de Colmeias Produtivas"
          type="number"
          v-model="colmeiasProdutivas"
          @input="emitSaveData"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Número de Colmeias Vazias"
          type="number"
          v-model="colmeiasVazias"
          @input="emitSaveData"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-simple-table dense class="tabela-dados">
      <thead>
        <tr>
          <th class="text-left">Descrição</th>
          <th class="text-left">Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Estimativa produção anual Cera (Kg)</td>
          <td>{{ formatNumber(estimativaCera) }}</td>
        </tr>
        <tr>
          <td>Estimativa produção anual Mel (Kg)</td>
          <td>{{ formatNumber(estimativaMel) }}</td>
        </tr>
        <tr>
          <td>Florada 1</td>
          <td>{{ formatNumber(florada1) }}</td>
        </tr>
        <tr>
          <td>Florada 2</td>
          <td>{{ formatNumber(florada2) }}</td>
        </tr>
        <tr>
          <td>Florada 3</td>
          <td>{{ formatNumber(florada3) }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-row dense>
      <v-col cols="4">
        <v-text-field
          label="Média Florada 1"
          type="number"
          v-model="media1"
          @input="emitSaveData"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Média Florada 2"
          type="number"
          v-model="media2"
          @input="emitSaveData"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Média Florada 3"
          type="number"
          v-model="media3"
          @input="emitSaveData"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    register: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      colmeiasProdutivas: 0,
      colmeiasVazias: 0,
      media1: 0,
      media2: 0,
      media3: 0,
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.emitSaveData();
    if (this.id) {
      await this.getHoney();
      await this.showHoney();
    }
  },
  methods: {
    formatNumber(value) {
      return new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },

    formatToDecimal(value) {
      if (typeof value === "string") {
        value = value.replace(/\./g, "").replace(",", ".");
      }
      return parseFloat(value).toFixed(10);
    },

    emitSaveData() {
      const data = {
        colmeias_produtivas: this.colmeiasProdutivas,
        colmeias_vazias: this.colmeiasVazias,
        media1: this.media1,
        media2: this.media2,
        media3: this.media3,
        producao_anual_cera: this.formatToDecimal(this.estimativaCera),
        producao_anual_mel: this.formatToDecimal(this.estimativaMel),
        florada1: this.formatToDecimal(this.florada1),
        florada2: this.formatToDecimal(this.florada2),
        florada3: this.formatToDecimal(this.florada3),
      };

      console.log("data: ", data);
      this.$emit("save-data", data);
    },

    async showHoney() {
      try {
        const response = await this.minamelServices
          .HoneyAppointmentService()
          .showHoney(this.id);

        if (response && response.data) {
          const data = response.data;

          this.colmeiasVazias = data[0].colmeias_vazias;
          this.media1 = data[0].media_1;
          this.media2 = data[0].media_2;
          this.media3 = data[0].media_3;

          this.emitSaveData();
        } else {
          console.warn("Os dados retornados são inválidos.");
        }
      } catch (error) {
        console.error("Erro ao buscar os dados de honey:", error);
      }
    },

    async getHoney() {
      try {
        const response = await this.minamelServices
          .HoneyAppointmentService()
          .getHoney(this.register.import_key);
        if (response && response.data && Array.isArray(response.data)) {
          const totalQuantity = response.data.reduce((total, record) => {
            return total + (record.hive_count || 0);
          }, 0);

          if (totalQuantity) {
            this.colmeiasProdutivas = totalQuantity;
          } else {
            this.colmeiasProdutivas = 0;
          }
          this.emitSaveData();
        } else {
          console.warn(
            "Os dados retornados não são uma lista ou estão incorretos."
          );
        }
      } catch (error) {
        console.error("Erro ao buscar os dados de honey:", error);
      }
    },
  },
  computed: {
    estimativaCera() {
      const florada1Total = this.media1 * this.colmeiasProdutivas;
      const florada2Total = this.media2 * this.colmeiasProdutivas;
      const florada3Total = this.media3 * this.colmeiasProdutivas;
      const producaoTotal = florada1Total + florada2Total + florada3Total;

      return producaoTotal * 0.02;
    },

    estimativaMel() {
      const florada1Total = this.media1 * this.colmeiasProdutivas;
      const florada2Total = this.media2 * this.colmeiasProdutivas;
      const florada3Total = this.media3 * this.colmeiasProdutivas;
      return florada1Total + florada2Total + florada3Total;
    },

    florada1() {
      return this.media1 * this.colmeiasProdutivas;
    },

    florada2() {
      return this.media2 * this.colmeiasProdutivas;
    },

    florada3() {
      return this.media3 * this.colmeiasProdutivas;
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
