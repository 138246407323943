<template>
  <v-container fluid align-content-space-between>
    <v-row row>
      <v-col xs="12">
        <v-subheader>
          <h2>Projeto</h2>
        </v-subheader>
      </v-col>
    </v-row>

    <v-row wrap row>
      <v-col xs="7" md="4">
        <v-text-field
          v-model="table.term"
          ref="search"
          label="Faça a sua busca"
          clear-icon="close"
          clearable
          append-icon="search"
          @click:append="getData"
          @keyup="getData"
          @keyup.enter="getData"
        />
      </v-col>
      <v-col xs="3" md="2" class="text-xs-left">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              large
              color="normal"
              v-on="on"
              @click="filters = !filters"
              v-shortkey="['ctrl', 'f']"
            >
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <span>Clique para selecionar os filtros</span>
        </v-tooltip>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex text-xs-right" style="justify-content: end">
        <v-btn large @click="register" color="primary">+ Incluir</v-btn>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-row row wrap v-show="filters">
        <v-col md="12">
          <v-card class="elevation-1 pa-3 mb-4">
            <v-row row>
              <v-col xs="12">
                <v-label>Selecione os campos para fazer a busca</v-label>
              </v-col>
            </v-row>

            <v-row row wrap>
              <v-col xs="12" md="4" pa-3>
                <v-select
                  clearable
                  chips
                  deletable-chips
                  color="primary"
                  :items="statusOptions"
                  v-model="status"
                  item-value="value"
                  item-text="name"
                  label="Status"
                  @change="getData"
                ></v-select>
              </v-col>
              <v-col xs="12" md="4" pa-3>
                <v-select
                  clearable
                  chips
                  deletable-chips
                  color="primary"
                  :items="apiaryGroups"
                  v-model="apiaryGroupsSelected"
                  item-value="id"
                  item-text="name"
                  label="Grupos de Apiário/Produtor"
                  @change="getData"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="
            item.status === 'P' ? 'orange' : item.status === 'F' ? 'green' : ''
          "
          text-color="white"
        >
          {{
            item.status === "P"
              ? "Pendente"
              : item.status === "F"
              ? "Finalizado"
              : ""
          }}
        </v-chip>
      </template>

      <template v-slot:[`item.apiary_group_id`]="{ item }">
        <td class="text-xs-left">
          {{ item.apiaryGroup ? item.apiaryGroup.name : "" }}
        </td>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn :to="`project/edit/${item.id}`" icon flat v-on="on">
              <v-icon color="#707070">edit</v-icon>
            </v-btn>
          </template>
        </v-menu>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn @click="remove(item)" icon flat v-on="on">
              <v-icon color="#707070">delete</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </template>
      <template slot="no-data">
        <div class="text-xs-center">{{ table.noDataText }}</div>
      </template>
    </v-data-table>

    <div>
      <v-card class="pt-3">
        <v-row wrap>
          <v-col xs="12" md="12" class="text-xs-center">
            <v-pagination
              v-model="table.pagination.page"
              :length="table.pagination.pages"
              v-bind:disabled="table.disabled"
              :total-visible="table.pagination.totalVisible"
              prev-icon="arrow_left"
              next-icon="arrow_right"
              primary
            />
          </v-col>
          <v-col xs="12" md="3">&nbsp;</v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Project",
  data() {
    return {
      term: "",
      apiaryGroups: [],
      apiaryGroupsSelected: null,
      status: "",
      statusOptions: [
        { value: "P", name: "Pendente" },
        { value: "F", name: "Finalizado" },
      ],
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
      filters: false,
      table: {
        headers: [
          {
            text: "Status",
            align: "center",
            value: "status",
            width: "100px",
          },
          { text: "Descrição", align: "left", value: "name", width: "300px" },
          {
            text: "Grupo",
            align: "left",
            value: "apiary_group_id",
            width: "200px",
          },
          { text: "Informações", align: "left", value: "info", width: "650px" },
          {
            text: "",
            align: "right",
            value: "active",
            width: "190px",
            sortable: false,
          },
        ],
        items: [],
        term: "",
        loading: false,
        noResultText: this.CONSTANTS.texts.table.noResultText,
        rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        noDataText: this.CONSTANTS.texts.table.noDataText,

        pagination: {
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          perPageItems: this.CONSTANTS.arrays.table.itensToView,
          page: 1,
          total: 0,
          pages: 0,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      selected: {},
      timelineData: [],
    };
  },
  mounted() {
    this.table.term = this.$functions.getSessionStorageSearchTerm("project");

    this.getApiaryGroups();
    this.getData();
  },
  methods: {
    async getApiaryGroups() {
      try {
        const response = await this.minamelServices
          .ApiaryGroupService()
          .getApiaryGroup()
          .search();

        this.apiaryGroups = response.data;
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message =
          "Ocorreu um erro ao buscar os grupos de apiários";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
    async remove(item) {
      const question = `Deseja realmente excluir o projeto" ${item.name}?`;
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices.ProjectServiceV2().delete(item.id);

      if (res) {
        this.getData();
      }
    },

    alertConfirm(ask) {
      let returnAlert = this.$swal
        .fire({
          title: `${ask}`,
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return false;
          }
          return true;
        });
      return returnAlert;
    },
    async getData() {
      this.$functions.setSessionStorageSearchTerm("project", this.table.term);

      this.table.loading = true;

      const params = {
        page: this.table.pagination.page,
        perPage: this.table.pagination.perPage,
        term: this.table.term,
        status: this.status ? this.status : "",
        apiaryGroup: this.apiaryGroupsSelected ? this.apiaryGroupsSelected : "",
      };

      try {
        const response = await this.minamelServices
          .ProjectServiceV2()
          .getAll(params);

        this.table.items = response.data;
        this.table.pagination.pages = response.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    async register() {
      try {
        const project = await this.minamelServices
          .ProjectServiceV2()
          .getDraft();

        this.$router.push(`/project/edit/${project.id}`);
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao criar o projeto";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
  watch: {
    "table.pagination.page": function () {
      this.getData();
    },
  },
};
</script>

<style>
.tableButton {
  margin: 6px 0px 6px 0px;
}
.active-item {
  background-color: red;
}
</style>
