<template>
  <v-container fluid :class="projectId ? 'pa-2' : ''">
    <v-card
      :class="!projectId ? 'elevation-1 pa-3 mb-4' : 'elevation-0 pa-0 ma-0'"
    >
      <v-form ref="form" :valid="valid">
        <v-row row wrap>
          <v-col xs="12" v-if="!projectId">
            <v-subheader>
              <h2>{{ headerText }} Apiário</h2>
            </v-subheader>
          </v-col>

          <v-col xs="12" md="12" pa-3>
            <span class="required grey--text"
              >* Preenchimento obrigatório.</span
            >
          </v-col>

          <v-col md="4" xs="12" class="pl-3 pr-3">
            <LatLngComponent
              @updateValue="
                (newValue) => {
                  this.register.latitude = newValue;
                }
              "
              :class="'required'"
              :value="register.latitude"
              typeLatLng="lat"
            />
          </v-col>

          <v-col md="4" xs="12" class="pl-3 pr-3">
            <LatLngComponent
              @updateValue="
                (newValue) => {
                  this.register.longitude = newValue;
                }
              "
              :class="'required'"
              :value="register.longitude"
              typeLatLng="lng"
            />
          </v-col>
          <v-col class="text-xs-left pt-3" xs="12" md="2">
            <v-btn style="color: white" color="blue" @click="maps(register)">
              <v-icon dark>location_on</v-icon>Mapa
            </v-btn>
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-text-field
              :rules="[formRules.required]"
              v-model="register.name"
              label="Nome"
              class="required mt-3"
              ref="name"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-autocomplete
              chips
              deletable-chips
              clearable
              v-model="register.supplier_import_key"
              :items="suppliers"
              :loading="!suppliers.length"
              item-text="name"
              item-value="import_key"
              label="Produtor"
              :rules="[formRules.required]"
              @change="onSupplierChange"
            >
              <template slot="item" slot-scope="data">
                {{ data.item.name }}
                <span class="font-weight-bold">
                  - COD.
                  {{ data.item.erp_code }}</span
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="register.apiary_group_id" xs="12" md="4" class="pa-3">
            <v-select
              clearable
              multiple
              chips
              deletable-chips
              :items="floweries"
              v-model="register.floweries"
              item-value="id"
              item-text="name"
              label="Florada"
              ref="flowery"
            >
            </v-select>
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <EstadosComponent
              :class="'required'"
              :value="register.uf"
              @updateEstado="updateListCidades"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <CidadesComponent
              :class="'required'"
              :city="register.city"
              :value="register.uf"
              @updateCidade="updateSelectedCidade"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-select
              class="required"
              clearable
              color="primary"
              chips
              deletable-chips
              :items="apiaryGroups"
              v-model="register.apiary_group_id"
              item-value="id"
              item-text="name"
              label="Grupo de apiário/produtor"
              ref="apiary_group_id"
            ></v-select>
          </v-col>

          <!-- <v-col xs="12" md="4" class="pa-3">
            <v-textarea
              class="required"
              rows="1"
              v-model="register.locale"
              label="Localidade"
              style="margin-top: 15px"
              ref="locale"
              :rules="[formRules.required]"
            />
          </v-col> -->

          <v-col xs="12" md="4" class="pa-3">
            <v-select
              class="required"
              clearable
              color="primary"
              chips
              deletable-chips
              :items="permanenceItems"
              v-model="register.permanence"
              item-value="id"
              item-text="name"
              label="Permanência"
              ref="permanence"
              :rules="[formRules.required]"
            ></v-select>
          </v-col>

          <v-col
            xs="12"
            md4
            class="pa-3"
            v-if="register.permanence == 'Migratório'"
          >
            <v-select
              class="required"
              clearable
              color="primary"
              chips
              deletable-chips
              :items="CONSTANTS.months"
              v-model="register.months"
              item-value="value"
              item-text="name"
              label="Meses"
              :rules="[formRules.required]"
              multiple
            >
              <template v-slot:selection="{ item }">
                <v-chip>
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col xs="12" md="4" class="pa-3 mt-2">
            <v-text-field
              v-model="register.hive_count"
              type="number"
              label="Quantidade de colméias"
              min="0"
              class="required"
              ref="hive_count"
              :rules="[formRules.required]"
            />
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-select
              class="required"
              clearable
              color="primary"
              chips
              deletable-chips
              :items="qualityItems"
              v-model="register.quality"
              item-value="id"
              item-text="name"
              label="Qualidade"
              ref="quality"
              :rules="[formRules.required]"
            ></v-select>
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-select
              clearable
              color="primary"
              chips
              deletable-chips
              :items="harvestItems"
              @change="onChangeHarvest"
              v-model="register.harvest"
              item-value="id"
              item-text="name"
              label="Safra"
              ref="harvest"
              multiple
            >
              <template v-slot:selection="{ item }">
                <v-chip>
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-switch
              v-model="register.active"
              color="#b9041c"
              :label="register.active ? 'Ativo' : 'Inativo'"
            ></v-switch>
          </v-col>

          <v-col xs="12" md="4" class="pa-3">
            <v-textarea
              v-model="register.description"
              label="Observações"
              rows="4"
              auto-grow
              color="primary"
              clearable
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          style="color: white"
          color="blue"
          class="mx-4"
          @click="save(true, true)"
          :loading="saving"
          v-if="!projectId && register.id"
          >Salvar e Atualizar projetos</v-btn
        >
        <v-btn
          style="color: white"
          color="green"
          @click="save(false, true)"
          :loading="saving_unique"
          >Salvar</v-btn
        >
        <v-btn color="error" text @click="cancel">Cancelar</v-btn>
      </v-card-actions>

      <!-- maps dialog -->
      <v-dialog
        v-model="dialogMaps"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            style="position: absolute; top: 0; z-index: 9999; width: 100%"
            dark
            color="primary"
          >
            <v-btn icon dark @click="closeDialog">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{
              apiaryMaps ? apiaryMaps.name : ""
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <MyMap ref="myMap" :needUpdate="viewMap"></MyMap>
        </v-card>
      </v-dialog>
      <!-- fim maps dialog -->
    </v-card>
  </v-container>
</template>

<script>
import { bus } from "@/main";
import jwt from "vue-jwt-decode";

import GoogleMapsApiLoader from "google-maps-api-loader";

import EstadosComponent from "../estadosCidades/EstadosComponent";
import CidadesComponent from "../estadosCidades/CidadesComponent";
import LatLngComponent from "../inputLatLng/LatLngComponent";
import MyMap from "../Maps/MyMap";

export default {
  name: "RegisterApiary",
  props: {
    projectId: { type: Number, default: null },
    routeName: { type: String, default: "" },
  },
  components: {
    EstadosComponent,
    CidadesComponent,
    LatLngComponent,
    MyMap,
  },
  data: () => ({
    id: null,
    apiaryGroups: [],
    suppliers: [],
    floweries: [],
    harvest: [],
    register: {
      active: true,
    },
    viewMap: true,
    saving: false,
    saving_unique: false,
    valid: false,
    formRules: {
      required: (v) => !!v || "Campo obrigatório!",
    },
    status: [
      { id: 1, description: "Ativo" },
      { id: 2, description: "Inativo" },
    ],
    qualityItems: [
      { id: 1, name: "Convencional" },
      { id: 2, name: "Orgânico" },
    ],
    harvestItems: [
      { id: 1, name: "1" },
      { id: 2, name: "2" },
      { id: 3, name: "3" },
    ],
    permanenceItems: ["Fixo", "Migratório"],
    arrayProjects: [],
    observations: [],
    observation: null,
    apiaryMaps: null,
    dialogMaps: false,
    headerText: "Novo",
    formValid: true,

    systemId: null,
    systemData: null,
    userData: null,
    token: null,

    snackbar: {
      message: "",
      visible: false,
      color: "success",
      timeout: 6000,
      x: "right",
      y: "top",
      multiLine: false,
    },
  }),
  created() {
    bus.$on("save_observation", async () => {
      await this.updateOrCreate(false, false);

      await this.$refs.myMap.loadDataMap(this.apiaryMaps, this.observations);
    });
  },
  async mounted() {
    this.userData = sessionStorage.token
      ? jwt.decode(sessionStorage.token).userData
      : null;
    this.systemId = sessionStorage.systemId ? sessionStorage.systemId : null;
    this.systemData = sessionStorage.token
      ? jwt.decode(sessionStorage.token).systemData[this.systemId]
      : null;

    if (this.$route.params.id) {
      this.id = this.projectId
        ? this.$route.params.childrenId
        : this.$route.params.id;

      this.headerText = "Editar";

      await this.getData();
    }

    await this.getApiaryGroups();
    await this.getBeekpeers();
  },

  methods: {
    async getFloweryByGroup(apiaryGroupId) {
      const params = {
        active: 1,
        apiaryGroupId: apiaryGroupId,
      };

      const response = await this.minamelServices
        .FloweryServiceV2()
        .get(params);
      if (apiaryGroupId) {
        this.floweries = response.data;
      }
    },

    async getBeekpeers() {
      // const params = {
      // 	active: 1,
      // 	beekeepers: true
      // };
      const response = await await this.minamelServices
        .SupplierServiceV2()
        .getBeekpeers();

      this.suppliers = response.data;
    },

    async onChangeHarvest() {
      console.log("this.register.harvest: ", this.register.harvest);
    },
    async getApiaryGroups() {
      const response = await this.minamelServices
        .ApiaryGroupServiceV2()
        .getAll({ active: 1 });

      this.apiaryGroups = response.data;
    },

    async geocodeLatLng(lat, lng) {
      lat = this.minamelServices
        .LatLngService()
        .checkLatLngType(lat, { typeLatLng: "lat" }).extra[0].newValue;

      lng = this.minamelServices
        .LatLngService()
        .checkLatLngType(lng, { typeLatLng: "lng" }).extra[0].newValue;

      const latlng = {
        lat: parseFloat(lat.substring(0, lat.length - 1)),
        lng: parseFloat(lng.substring(0, lng.length - 1)),
      };

      const google = await GoogleMapsApiLoader({
        libraries: ["geometry", "geocoder"],
        apiKey: this.CONFIG.VUE_APP_API_KEY_GOOGLE_MAPS,
      });

      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          const place = this.parseLocation(results[0]);

          this.setAddress(place);
        }
      });
    },

    parseLocation(place) {
      var location = [];

      place.address_components.map((element) => {
        switch (element.types[0]) {
          case "administrative_area_level_1":
            location["state"] = element.long_name;
            location["uf"] = element.short_name;
            break;
          case "administrative_area_level_2":
            location["city"] = element.long_name;
            break;
          case "country":
            location["country"] = element.long_name;
            break;
        }
      });

      return location;
    },

    setAddress(address) {
      this.register.uf = address.uf;
      this.register.city = address.city;
    },

    async maps(item) {
      this.apiaryMaps = item;

      await this.openDialog();

      await this.$refs.myMap.loadDataMap(this.apiaryMaps, this.observations);
    },

    async openDialog() {
      this.dialogMaps = true;

      await this.$functions.delay(500);

      document.querySelector("html").style.overflow = "hidden";
    },

    async closeDialog() {
      this.dialogMaps = false;

      let marker = this.getMarkers().marker;
      let description = this.$refs.myMap.getFields().description;
      let risks = this.$refs.myMap.getFields().risks;

      if (marker || risks || description) {
        this.register.latitude = this.minamelServices
          .LatLngService()
          .checkLatLngType(marker.getPosition().lat().toString(), {
            typeLatLng: "lat",
          }).newValue;
        this.register.longitude = this.minamelServices
          .LatLngService()
          .checkLatLngType(marker.getPosition().lng().toString(), {
            typeLatLng: "lng",
          }).newValue;

        await this.geocodeLatLng(
          this.register.latitude,
          this.register.longitude
        );
      }

      this.register.observations = this.getMarkers().observations;
      this.register.observation = this.getFields().description;

      document.querySelector("html").style.overflow = null;
    },

    verifyPermission(supplier) {
      let allow = "Informe um produtor";
      if (!supplier || !supplier.erp_code) return allow;

      if (supplier.aggregate_id) {
        // verifica se o supplier esta relacionado com agregados
        this.snackbar.color = "error";
        this.snackbar.message = `Este produtor está inserido no agregado ${supplier.aggregate_name}`;
        this.$root.$SnackBar.show(this.snackbar);
        this.register.supplier_name = "";
        this.register.supplier_id = null;
        return this.snackbar.message;
      }

      allow = true;

      if (
        supplier.erp_code.includes("A") &&
        this.systemId &&
        supplier.systems_ids
      ) {
        // verifica se o supplier é um agregado
        allow = supplier.systems_ids
          .split(",")
          .some((value) => value.trim() === this.systemId);

        if (!allow) {
          this.snackbar.color = "error";
          this.snackbar.message = `Este fornecedor não tem permissão para ${this.systemData.name}`;
          this.$root.$SnackBar.show(this.snackbar);

          allow = `Este fornecedor não tem permissão para ${this.systemData.name}`;

          this.register.supplier_name = "";
          this.register.supplier_id = null;
        }
      }

      this.register.supplier_name = supplier.aggregate_name
        ? supplier.aggregate_name
        : supplier.name;
      this.register.supplier_id = supplier.id;
      return allow;
    },

    verifyFlowery(flowery) {
      if (!flowery || !flowery.length || flowery.length <= 0) {
        return "É necessário informar uma florada!";
      }
      return true;
    },

    verifyUf(uf) {
      if (!uf || !uf.length || uf.length <= 0) {
        return "É necessário informar um Estado!";
      }
      return true;
    },

    verifyCity(city) {
      if (!city || !city.length || city.length <= 0) {
        return "É necessário informar uma Cidade!";
      }
      return true;
    },

    updateSelectedCidade(value) {
      this.register.city = value;
    },

    updateListCidades(value) {
      this.$set(this.register, "uf", value);

      if (!value) {
        this.updateSelectedCidade(null);
      }
    },

    cancel() {
      this.$router.back();
    },

    async updateProjectApiary(projectSelected) {
      if (this.observations) {
        this.observations = this.observations.map((remark) => {
          delete remark.apiary_id;
          delete remark.id;
          return remark;
        });
      }
      if (projectSelected.length && projectSelected.length > 0) {
        let alertConfirm = await this.alertConfirm(
          "Atualizar este apiário nos projetos selecionados?"
        );
        if (alertConfirm) {
          // eslint-disable-next-line no-unused-vars
          for (const key in projectSelected) {
            await projectSelected.map(async (project) => {
              let oldApiary = project.apiary.find((apiary) => {
                return apiary.apiary_id === this.$route.params.id;
              });
              if (oldApiary) {
                let oldApiaryId = oldApiary.id;
                let newApiary = {
                  ...oldApiary,
                  ...this.register,
                  observations: this.observations,
                  id: oldApiaryId,
                };

                try {
                  // eslint-disable-next-line no-unused-vars
                  let updateApiary = await this.minamelServices
                    .ProjectApiaryServiceV2()
                    .updateProjectApiary()
                    .update(newApiary);
                } catch (err) {
                  console.error(err);
                }
              }
            });
          }
          projectSelected = [];
          this.save();
        }
      }
    },

    async save(saveProjects, redirect) {
      try {
        if (saveProjects) {
          this.saving = true;
        } else {
          this.saving_unique = true;
        }

        let formValidate = this.$refs.form.validate();

        if (!formValidate) {
          this.saving = false;
          this.saving_unique = false;

          this.snackbar.color = "warning";
          this.snackbar.message = "Verifique os campos em destaque.";
          this.$root.$SnackBar.show(this.snackbar);
          return;
        }

        this.updateOrCreate(saveProjects, redirect);
      } catch (error) {
        console.error(error);

        this.saving = false;
        this.saving_unique = false;
      }
    },

    async updateOrCreate(saveProjects, redirect) {
      if (this.getMarkers() || this.getFields()) {
        const fields = this.getFields();
        if (fields) {
          this.register.observation = fields.description || "";
          this.register.risks = fields.risks
            ? fields.risks.map((risk) => risk.name)
            : [];
          this.register.observations = this.getMarkers().observations;
        }
      }
      this.observations = this.register.observations;
      this.observation = this.register.observation;
      this.risk = this.register.risks;

      this.register.latitude = this.minamelServices
        .LatLngService()
        .checkLatLngType(this.register.latitude, {
          typeLatLng: "lat",
        }).latLng;
      this.register.longitude = this.minamelServices
        .LatLngService()
        .checkLatLngType(this.register.longitude, {
          typeLatLng: "lng",
        }).latLng;

      if (this.register.permanence === "Fixo") {
        this.register.months = [];
      }

      if (Array.isArray(this.register.harvest)) {
        this.register.harvest = this.register.harvest.join(",");
      } else {
        this.register.harvest = String(this.register.harvest);
      }

      if (
        this.register &&
        this.register.months &&
        this.register.months.length
      ) {
        this.register.months = this.register.months.join(",");
      } else {
        this.register.months = "";
      }

      if (saveProjects) {
        let alertConfirm = await this.alertConfirm(
          "Atualizar este apiário nos projetos existentes?"
        );
        if (alertConfirm) {
          this.update(saveProjects, redirect);
          return;
        }
        return;
      }

      if (this.id) {
        this.update(null, redirect);
        return;
      }

      this.create(redirect);
    },

    async create(redirect) {
      try {
        const response = await this.minamelServices
          .ApiaryServiceV2()
          .store(this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Apiário incluído com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          if (redirect) {
            this.$router.push(`/apiaries-select`);
          }
        }
      } catch (err) {
        this.saving = false;
        this.saving_unique = false;

        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        console.error(err);
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update(saveProjects, redirect = true) {
      if (saveProjects) {
        this.register.saveProjects = 1;
      }

      try {
        const response = await this.minamelServices
          .ApiaryServiceV2()
          .update(this.id, this.register);

        if (response) {
          if (redirect) {
            this.snackbar.color = "success";
            this.snackbar.message = "Apiário alterado com sucesso!";
            this.$root.$SnackBar.show(this.snackbar);

            if (this.routeName) {
              this.$router.push(`${this.routeName}/apiaries`);
            } else {
              this.$router.push("/apiaries-select");
            }
          }
        }
      } catch (err) {
        this.saving = false;
        this.saving_unique = false;

        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    getMarkers() {
      if (!this.$refs || !this.$refs.myMap || !this.$refs.myMap.getMarkers) {
        return;
      }

      return this.$refs.myMap.getMarkers();
    },

    getFields() {
      if (!this.$refs || !this.$refs.myMap || !this.$refs.myMap.getFields) {
        return;
      }
      const fields = this.$refs.myMap.getFields();
      return fields;
    },

    alertConfirm(ask) {
      let returnAlert = this.$swal
        .fire({
          title: `${ask}`,
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return false;
          }
          return true;
        });
      return returnAlert;
    },

    async getData() {
      const response = await this.minamelServices
        .ApiaryServiceV2()
        .show(this.id);

      this.register = response.data;
      this.observations = response.data.observations;
      this.fields = response.data.fields;

      this.register.floweries = this.register.floweries.map((item) => item.id);

      if (this.register.months && this.register.months.length) {
        this.register.months = this.register.months.split(",");
      } else {
        this.register.months = "";
      }

      // this.register.harvest = [1,"2"

      if (this.register.harvest) {
        this.register.harvest = this.register.harvest.split(",").map(Number);
      } else {
        this.register.harvest = "";
      }
    },

    verifyLat(value) {
      return this.minamelServices
        .LatLngService()
        .checkLatLngType(value, { typeLatLng: "lat" })
        ? true
        : "Informe uma latitude válida.";
    },

    verifyLng(value) {
      return this.minamelServices
        .LatLngService()
        .checkLatLngType(value, { typeLatLng: "lng" })
        ? true
        : "Informe uma longitude válida.";
    },

    onSupplierChange(selectedSupplierId) {
      if (selectedSupplierId) {
        const sup = this.suppliers.filter(
          (sup) => sup.import_key === selectedSupplierId
        )[0];
        this.register.apiary_group_id = sup.apiary_group_id;
      }
    },
  },

  watch: {
    "register.permanence": {
      handler(val) {
        if (val === "Fixo") {
          this.register.months = "";
        }
      },
      deep: true,
    },
    "register.apiary_group_id": {
      handler(newGroupId) {
        this.getFloweryByGroup(newGroupId);
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.v-date-picker-header.theme--light {
  display: none !important;
}
.v-picker__title.primary {
  display: none !important;
}
</style>
