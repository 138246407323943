<template>
  <v-container fluid>
    <v-row row v-if="!projectId">
      <v-col xs="12" lg12 md="12">
        <v-subheader>
          <h2>Apiários</h2>
        </v-subheader>
      </v-col>
    </v-row>

    <v-row row justify-space-between>
      <v-col xs="7" md="5">
        <v-text-field
          v-model="filter.term"
          ref="search"
          label="Faça a sua busca"
          clear-icon="close"
          clearable
          append-icon="search"
          @click:append="getData"
          @keyup="getData"
          @keyup.enter="getData"
        />
      </v-col>

      <v-col xs="3" md="4" class="text-xs-left">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              large
              color="normal"
              v-on="on"
              @click="toggleFilters"
              v-shortkey="['ctrl', 'f']"
            >
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <span>Clique para selecionar os filtros</span>
        </v-tooltip>

        <span
          v-if="!projectId"
          class="font-weight-bold mx-auto"
          style="padding-left: 10px"
        >
          Total de colmeias: {{ hiveCount || 0 }}
        </span>
      </v-col>

      <v-col class="d-flex">
        <v-spacer />
        <v-btn text color="primary" large @click="seeAllApiariesFromList">
          {{
            table.pagination.perPage != -1
              ? "Ver Todos os Apiários"
              : "Ver Apiários Paginados"
          }}
        </v-btn>
        <v-btn
          v-if="!importToProject"
          color="primary"
          large
          :to="!projectId ? 'apiaries/register' : ''"
          @click="importTo"
        >
          + {{ projectId && !importToProject ? "Importar" : "Incluir" }}
        </v-btn>
      </v-col>
      <!-- <v-col class="text-xs-right d-flex">
        <div>
          <v-btn color="primary" @click="triggerFileInput">
            Importar Arquivo
          </v-btn>
          <input
            ref="fileInput"
            type="file"
            @change="handleFileUpload"
            accept=".xlsx"
            style="display: none"
          />
        </div>
      </v-col> -->
    </v-row>

    <v-expand-transition>
      <v-row row wrap v-show="expandFilters">
        <v-col md="12">
          <v-card class="elevation-1 pa-3 mb-4">
            <v-row row>
              <v-col xs="12">
                <v-label> Selecione os campos para fazer a busca </v-label>
              </v-col>
            </v-row>

            <v-row row wrap>
              <v-col xs="12" md="3" pa-3>
                <v-select
                  chips
                  deletable-chips
                  clearable
                  v-model="filter.active"
                  :items="activeItems"
                  label="Ativo"
                  @change="getData"
                />
              </v-col>

              <v-col xs="12" md="3" pa-3>
                <v-autocomplete
                  chips
                  deletable-chips
                  clearable
                  v-model="filter.supplier"
                  :items="suppliers"
                  :loading="!suppliers.length"
                  item-text="name"
                  item-value="import_key"
                  label="Produtor"
                  @change="getData"
                >
                  <template slot="item" slot-scope="data">
                    {{ data.item.name }}
                    <span class="font-weight-bold">
                      - COD. {{ data.item.id }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col xs="12" md="3" pa-3>
                <v-select
                  chips
                  deletable-chips
                  clearable
                  v-model="filter.flowery"
                  :items="floweries"
                  item-text="name"
                  item-value="id"
                  label="Florada"
                  @change="getData"
                />
              </v-col>

              <v-col xs="12" md="3" pa-3>
                <v-select
                  chips
                  deletable-chips
                  clearable
                  v-model="filter.permanence"
                  :items="permanenceItems"
                  label="Permanência"
                  @change="getData"
                />
              </v-col>

              <v-col xs="12" md="3" pa-3>
                <v-select
                  chips
                  deletable-chips
                  clearable
                  v-model="filter.quality"
                  :items="qualityItems"
                  label="Qualidade"
                  @change="getData"
                />
              </v-col>

              <!-- <EstadosComponent @change="getData" v-model="filter.uf" />W
							<CidadesComponent @change="getData" /> -->
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.supplier_import_key`]="{ item }">
        {{ item.supplier ? item.supplier.name : "-" }}
      </template>
      <template v-slot:[`item.apiary_group_id`]="{ item }">
        {{ item.apiaryGroup ? item.apiaryGroup.name : "-" }}
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-chip v-if="item.active == 1" color="green" text-color="white"
          >Ativo</v-chip
        >
        <v-chip v-else color="#5e636b" text-color="white">Inativo</v-chip>
      </template>

      <template v-slot:[`item.city`]="{ item }">
        {{ item.city == "null" ? "" : `${item.city} /` }}
        {{ item.uf == "null" ? "" : item.uf }}
      </template>

      <template v-slot:[`item.floweries`]="{ item }">
        {{ item.floweries.length ? getFloweryNames(item.floweries) : "-" }}
      </template>

      <template v-slot:[`item.latLng`]="{ item }">
        <v-tooltip transition="fab-transition" top>
          <v-chip
            class="caption ma-1"
            slot="activator"
            label
            small
            color="#0ebcff"
            text-color="white"
          >
            {{ getLatLngValues(item.latitude, "lat") }}</v-chip
          >
          <span>
            <ul
              v-for="lat in minamelServices
                .LatLngService()
                .checkLatLngType(item.latitude, {
                  typeLatLng: 'lat',
                })
                ? minamelServices
                    .LatLngService()
                    .checkLatLngType(item.latitude, {
                      typeLatLng: 'lat',
                    }).extra
                : []"
              :key="lat.type"
            >
              <li>
                {{ `${lat.type}: ${convertCoordinates(lat.newValue)}` }}
              </li>
            </ul>
          </span>
        </v-tooltip>
        <v-tooltip transition="fab-transition" top>
          <v-chip
            slot="activator"
            small
            label
            class="caption ma-1"
            color="#d4a041"
            text-color="white"
          >
            {{ getLatLngValues(item.longitude, "lng") }}
          </v-chip>
          <span>
            <ul
              v-for="lng in longitudeOptions ? longitudeOptions : []"
              :key="lng.type"
            >
              <li>
                {{ `${lng.type}: ${convertCoordinates(lng.newValue)}` }}
              </li>
            </ul>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              @click="openDialogDetails(item)"
              class="mx-1"
              icon
              text
              v-on="on"
            >
              <v-icon color="#707070">visibility</v-icon>
            </v-btn>
          </template>
          <span>Ver detalhes</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small @click="maps(item)" class="mx-1" icon text v-on="on">
              <v-icon color="#707070">gps_fixed</v-icon>
            </v-btn>
          </template>
          <span>Visualizar no mapa</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="!importToProject"
              :to="`apiaries/edit/${item.id}`"
              class="mx-1"
              icon
              v-on="on"
            >
              <v-icon color="#707070">edit</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              @click="remove(item.id)"
              class="mx-1"
              icon
              text
              v-on="on"
            >
              <v-icon color="#707070">delete</v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div>
      <v-card :class="projectId ? 'elevation-0 mt-1 pt-3' : 'elevation-2 pa-3'">
        <v-row wrap>
          <v-col xs="12" md="12" class="text-xs-center">
            <v-pagination
              v-if="table.pagination.perPage != -1"
              v-model="table.pagination.page"
              :length="table.pagination.pages"
              v-bind:disabled="table.disabled"
              :total-visible="table.pagination.totalVisible"
              prev-icon="arrow_left"
              next-icon="arrow_right"
              primary
            />
          </v-col>
        </v-row>
      </v-card>
    </div>

    <!-- import apiary dialog -->
    <v-dialog v-model="dialogImport" persistent width="900">
      <v-card class="'elevation-0">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogImport = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Importar apiário</v-toolbar-title>
        </v-toolbar>

        <v-row row justify-space-between class="pl-4 pr-4 pt-4">
          <v-col xs="7" md="5">
            <v-text-field
              v-model="allApiariesFilter.term"
              ref="search"
              label="Faça a sua busca"
              clear-icon="close"
              clearable
              append-icon="search"
              @click:append="getAllApiaries"
              @keyup="getAllApiaries"
              @keyup.enter="getAllApiaries"
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="allApiariesTable.headers"
          :items="allApiariesTable.items"
          :loading="allApiariesTable.loading"
          class="elevation-1 ml-4 mr-4 mb-4"
          item-key="id"
          show-select
          v-model="allApiariesTable.selected"
          hide-default-footer
        >
          <template v-slot:[`item.supplier`]="{ item }">
            {{ item.supplier ? item.supplier.name : "-" }}
          </template>

          <template v-slot:[`item.apiaryGroup`]="{ item }">
            {{ item.apiaryGroup ? item.apiaryGroup.name : "-" }}
          </template>
        </v-data-table>

        <div class="text-xs-center pl-4 pr-4 pb-4">
          <v-pagination
            v-model="allApiariesTable.pagination.page"
            :length="allApiariesTable.pagination.pages"
            v-bind:disabled="allApiariesTable.disabled"
            :total-visible="allApiariesTable.pagination.totalVisible"
            prev-icon="arrow_left"
            next-icon="arrow_right"
            primary
          />
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!allApiariesTable.selected.length"
            @click="importDataToProject"
          >
            Importar selecionado(s)
          </v-btn>
          <v-btn text color="error" @click="dialogImport = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- maps dialog -->
    <v-dialog v-model="dialogMaps" fullscreen hide-overlay persistent>
      <v-card>
        <v-toolbar
          style="position: absolute; top: 0; z-index: 9999; width: 100%"
          dark
          color="primary"
        >
          <v-btn icon dark @click="this.closeDialogMaps">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ apiaryMaps ? apiaryMaps.name : "" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <MyMap ref="myMap"></MyMap>
      </v-card>
    </v-dialog>
    <!-- fim maps dialog -->

    <!-- detail dialog-->
    <v-dialog v-model="dialogDetails" width="1000">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="this.closeDialogDetails">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Apiário {{ selectedItem.name }} -
            {{ selectedItem.active == 1 ? "Ativo" : "Inativo" }}
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-sm-and-down">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="maps(selectedItem), (dialogDetails = !dialogDetails)"
                  icon
                  text
                  dark
                  v-on="on"
                >
                  <v-icon>gps_fixed</v-icon>
                </v-btn>
              </template>
              <span>Visualizar no mapa</span>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text style="padding-top: 10px">
          <v-row grid-list-md row wrap>
            <v-col md="4" pt-2>
              <v-label>Produtor/Agregado</v-label>
              <span class="d-block font-weight-bold text--grey">
                {{ selectedItem.supplier ? selectedItem.supplier.name : "-" }}
              </span>
            </v-col>

            <v-col md="4" pt-2>
              <v-label>Grupo de apiários</v-label>
              <span class="d-block font-weight-bold">
                {{
                  selectedItem.apiaryGroup ? selectedItem.apiaryGroup.name : "-"
                }}
              </span>
            </v-col>

            <v-col md="4" pt-2>
              <v-label>Florada</v-label>
              <span class="d-block font-weight-bold">
                {{
                  selectedItem.floweries
                    ? getFloweryNames(selectedItem.floweries)
                    : "-"
                }}
              </span>
            </v-col>

            <!-- <v-col md="4" pt-4>
              <v-label>Localidade</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.locale }}
              </span>
            </v-col> -->

            <v-col md="4" pt-4>
              <v-label>Cidade/UF</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.city }}/{{ selectedItem.uf }}
              </span>
            </v-col>

            <v-col md="4" pt-4>
              <v-label>Quantidade de colméias</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.hive_count }}
              </span>
            </v-col>

            <v-col md="4" pt-4>
              <v-label>Qualidade</v-label>
              <span class="d-block font-weight-bold">
                {{ getQualityName(selectedItem.quality) }}
              </span>
            </v-col>

            <v-col md="4" pt-4>
              <v-label>Permanência</v-label>
              <span class="d-block font-weight-bold">
                {{ selectedItem.permanence }}
              </span>
            </v-col>

            <v-col md4 pt-4 v-if="selectedItem.permanence === 'Migratório'">
              <v-label>Meses</v-label>
              <span class="d-block font-weight-bold">
                {{
                  selectedItem.months ? getMonthNames(selectedItem.months) : "-"
                }}
              </span>
            </v-col>

            <v-col
              md="4"
              pt-4
              v-if="selectedItem.latitude || selectedItem.longitude"
            >
              <v-label>Latitude/Longitude</v-label>
              <div class="coord">
                <v-tooltip transition="fab-transition" top>
                  <v-chip
                    slot="activator"
                    small
                    label
                    color="#0ebcff"
                    text-color="white"
                  >
                    {{ getLatLngValues(selectedItem.latitude, "lat") }}
                  </v-chip>
                  <span>
                    <ul v-for="lat in latitudeOptions" :key="lat.type">
                      <li>
                        {{ `${lat.type}: ${convertCoordinates(lat.newValue)}` }}
                      </li>
                    </ul>
                  </span>
                </v-tooltip>
                <v-tooltip transition="fab-transition" top>
                  <v-chip
                    slot="activator"
                    small
                    label
                    color="#d4a041"
                    text-color="white"
                  >
                    {{ getLatLngValues(selectedItem.longitude, "lng") }}
                  </v-chip>
                  <span>
                    <ul v-for="lng in longitudeOptions" :key="lng.type">
                      <li>
                        {{ `${lng.type}: ${convertCoordinates(lng.newValue)}` }}
                      </li>
                    </ul>
                  </span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDetails = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import * as XLSX from "xlsx";

// import EstadosComponent from "../estadosCidades/EstadosComponent";
// import CidadesComponent from "../estadosCidades/CidadesComponent";
import MyMap from "../Maps/MyMap";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Apiary",
  props: {
    apiaries: { type: Array },
    projectId: { type: Number, default: null },
    importToProject: { type: Boolean, default: false },
  },
  components: {
    MyMap,
  },
  data() {
    return {
      dialogMaps: false,
      dialogDetails: false,
      dialogImport: false,
      old_remarks: [],
      apiaryMaps: null,
      apiaryGroups: [],
      suppliers: [],
      floweries: [],
      hiveCount: 0,
      expandFilters: false,
      selected: [],
      selectedItem: {},
      filter: {
        term: "",
        active: "",
        permanence: "",
      },
      allApiariesFilter: {
        term: "",
      },
      activeItems: [
        { value: "1", text: "Sim" },
        { value: "0", text: "Não" },
      ],
      permanenceItems: [
        { value: "Fixo", text: "Fixo" },
        { value: "Migratório", text: "Migratório" },
      ],
      qualityItems: [
        { value: 1, text: "Convencional" },
        { value: 2, text: "Orgânico" },
      ],
      table: {
        headers: [
          {
            text: "Status",
            align: "left",
            value: "active",
            width: "100px",
            sortable: false,
          },
          {
            text: "Descrição",
            width: "250px",
            align: "left",
            value: "name",
          },
          {
            text: "Produtor/Agregado",
            align: "center",
            width: "180px",
            value: "supplier_import_key",
          },
          {
            text: "Cidade/UF",
            align: "left",
            width: "200px",
            value: "city",
          },
          {
            text: "Localidade",
            width: "150px",
            align: "left",
            value: "locale",
          },
          {
            text: "Grupo",
            align: "left",
            width: "160px",
            value: "apiary_group_id",
          },
          {
            text: "Permanência",
            align: "center",
            width: "140px",
            value: "permanence",
          },
          {
            text: "Florada",
            align: "center",
            width: "100px",
            value: "floweries",
            sortable: false,
          },
          {
            text: "Colmeias",
            align: "center",
            width: "50px",
            value: "hive_count",
            sortable: false,
          },
          {
            text: "Coordenadas",
            width: "190px",
            align: "left",
            value: "latLng",
          },
          {
            text: "",
            width: "190px",
            align: "right",
            value: "actions",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        pagination: {
          page: 1,
          rowsPerPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      allApiariesTable: {
        selected: [],
        headers: [
          { text: "Status", align: "left", value: "active" },
          { text: "Descrição", align: "left", value: "name" },
          {
            text: "Produtor/Agregado",
            align: "left",
            value: "supplier_import_key",
          },
          {
            text: "Grupo de apiário",
            align: "left",
            value: "apiary_group_id",
          },
          {
            text: "Permanência",
            align: "left",
            value: "permanence",
          },
          {
            text: "",
            align: "right",
            value: "active",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          rowsPerPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },
  async mounted() {
    const term = this.$functions.getSessionStorageSearchTerm("apiary");

    if (term && term != "") {
      this.filter.term = term;
    }

    await this.getBeekpeers();
    await this.getApiaryGroups();
    await this.getFloweries();
  },

  methods: {
    // triggerFileInput() {
    //   this.$refs.fileInput.click();
    // },
    seeAllApiariesFromList() {
      if (this.table.pagination.perPage == -1) {
        this.table.pagination.perPage = 10;
      } else {
        this.table.pagination.perPage = -1;
      }

      this.getData();
    },

    longitudeOptions(props) {
      const data = this.minamelServices
        .LatLngService()
        .checkLatLngType(props.item.longitude, {
          typeLatLng: "lng",
        });

      const result = data.extra;

      return result;
    },

    latitudeOptions(props) {
      const data = this.minamelServices
        .LatLngService()
        .checkLatLngType(props.item.latitude, {
          typeLatLng: "lat",
        }).extra;

      const result = data.extra;

      return result;
    },

    async getBeekpeers() {
      const response = await await this.minamelServices
        .SupplierServiceV2()
        .getBeekpeers();

      this.suppliers = response.data;
    },

    async getApiaryGroups() {
      const response = await this.minamelServices
        .ApiaryGroupServiceV2()
        .getAll({ active: 1 });

      this.apiaryGroups = response.data;
    },

    async getFloweries() {
      const response = await this.minamelServices
        .FloweryServiceV2()
        .get({ active: 1 });

      this.floweries = response.data;
    },

    async getData() {
      this.$functions.setSessionStorageSearchTerm("apiary", this.filter.term);

      try {
        this.table.loading = true;

        const params = {
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          active: this.filter.active ? this.filter.active : "",
          term: this.filter.term ? this.filter.term : "",
          supplier: this.filter.supplier ? this.filter.supplier : "",
          permanence: this.filter.permanence ? this.filter.permanence : "",
          quality: this.filter.quality ? this.filter.quality : "",
          apiaryGroup: this.projectId ? "all" : this.filter.apiaryGroup,
          flowery: this.filter.flowery ? this.filter.flowery : "",
          projectId: this.importToProject
            ? ""
            : this.projectId
            ? this.projectId
            : "",
          exceptProjectId: this.projectId ? this.projectId : "",
          type: this.importToProject
            ? "RECORD"
            : this.projectId
            ? "PROJECT"
            : "RECORD",
        };

        const response = await this.minamelServices
          .ApiaryServiceV2()
          .getAll(params);

        this.hiveCount = response.hive_total;
        this.table.pagination.pages = response.data.meta.last_page;
        this.table.items = response.data.data;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    convertCoordinates(value) {
      if (!value) {
        return "";
      }

      const arr = value.split(".");

      if (!arr || !arr[0] || !arr[1]) {
        return "";
      }

      return `${arr[0]}.${arr[1].substring(0, 2)}°`;
    },

    async getAllApiaries() {
      try {
        this.allApiariesTable.loading = true;

        const params = {
          page: this.allApiariesTable.pagination.page,
          perPage: this.allApiariesTable.pagination.perPage,
          active: 1,
          term: this.allApiariesFilter.term ? this.allApiariesFilter.term : "",
          exceptProjectId: this.projectId ? this.projectId : "",
          type: "RECORD",
        };

        const res = await this.minamelServices.ApiaryServiceV2().getAll(params);

        this.allApiariesTable.items = res.data.data;

        if (this.allApiariesTable.selected) {
          this.allApiariesTable.items.map((obj1) => {
            this.allApiariesTable.selected.map((obj2) => {
              if (obj1.id != obj2.id) {
                return;
              }

              if (obj2.checked) {
                obj1.checked = true;
              }
            });
          });
        }

        this.allApiariesTable.pagination.pages = res.data.meta.last_page;
        this.allApiariesTable.loading = false;
      } catch (err) {
        this.allApiariesTable.loading = false;
      }
    },

    async remove(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices.ApiaryServiceV2().delete(id);

      if (res) {
        this.table.pagination.page = 1;
        await this.getData();
      }
    },

    async alertConfirm(ask, type) {
      let response = await this.$swal.fire({
        title: `${ask}`,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });

      if (response.value) {
        return true;
      }

      return false;
    },

    getMonthNames(months) {
      months = months.split(",");
      let arr = [
        { id: "01", name: "Janeiro" },
        { id: "02", name: "Fevereiro" },
        { id: "03", name: "Março" },
        { id: "04", name: "Abril" },
        { id: "05", name: "Maio" },
        { id: "06", name: "Junho" },
        { id: "07", name: "Julho" },
        { id: "08", name: "Agosto" },
        { id: "09", name: "Setembro" },
        { id: "10", name: "Outubro" },
        { id: "11", name: "Novembro" },
        { id: "12", name: "Dezembro" },
      ];
      if (months) {
        return arr
          .filter((month) => {
            return months.some((mes) => {
              return mes === month.id;
            })
              ? month.name
              : null;
          })
          .map((month) => {
            return month.name;
          })
          .join(", ");
      }
    },

    getFloweryNames(floweries) {
      let data = "";
      let cont = 1;

      floweries.forEach((item) => {
        data += item.name;

        if (floweries.length !== cont) {
          data += ", ";
          cont += 1;
        }
      });

      return data;
    },

    getQualityName(value) {
      if (value === 1) return "Convencional";

      if (value === 2) return "Orgânico";

      return "-";
    },

    getLatLngValues(value) {
      return this.convertCoordinates(value);
    },

    async maps(item) {
      item.supplier_name = item.supplier.name;
      this.apiaryMaps = item;

      const observations = item.observations;
      const blockMap = true;
      this.dialogMaps = true;

      await this.$functions.delay(500);

      await this.$refs.myMap.loadDataMap(
        this.apiaryMaps,
        observations,
        blockMap
      );

      setTimeout(() => {
        this.openDialogMaps();
      }, 400);
    },

    async openDialogMaps() {
      this.dialogMaps = true;

      await this.$functions.delay(500);

      document.querySelector("html").style.overflow = "hidden";
    },

    async closeDialogMaps() {
      // this.dialogMaps = false;

      let marker = this.$refs.myMap.getMarkers().marker;
      let remarks = this.$refs.myMap.getMarkers().observations;
      let description = this.$refs.myMap.getFields().description;
      let risks = this.$refs.myMap.getFields().risks;

      if (marker || risks) {
        marker.id = this.apiaryMaps.import_key;

        const riskNames = risks.map((risk) => risk.name);

        // await this.$refs.myMap.updateApiaryLocation(marker);

        await this.$refs.myMap.updateApiaryRemark(
          this.apiaryMaps.import_key,
          remarks
        );

        await this.$refs.myMap.updateApiaryFields(
          this.apiaryMaps.import_key,
          description,
          riskNames
        );
      }

      const mapUrl = this.getStaticMapUrl(this.apiaryMaps);

      // const imageBase64 = await this.convertImageToBase64(mapUrl);

      // Criar um novo Canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = 800;
      canvas.height = 600;

      // Criar imagem do mapa
      const mapImg = new Image();
      mapImg.crossOrigin = "anonymous"; // Evita erro de CORS
      mapImg.src = mapUrl;

      // Esperar o mapa carregar
      mapImg.onload = async () => {
        ctx.drawImage(mapImg, 0, 0, canvas.width, canvas.height);

        // 🔹 Exemplo: Desenhar texto no mapa
        ctx.fillStyle = "red";
        ctx.font = "20px Arial";
        ctx.fillText("Ponto Marcado", 100, 100);

        // 🔹 Exemplo: Desenhar um círculo representando um marcador
        ctx.beginPath();
        ctx.arc(200, 200, 10, 0, 2 * Math.PI);
        ctx.fillStyle = "blue";
        ctx.fill();

        // Converter o canvas para Base64
        const imageBase64 = canvas.toDataURL("image/png");

        // 🔹 Enviar a imagem para o backend
        await this.minamelServices
          .ApiaryServiceV2()
          .downloadImage(this.apiaryMaps.import_key, imageBase64);

        this.dialogMaps = false;

        document.querySelector("html").style.overflow = null;

        this.getData();
      };
    },

    getStaticMapUrl(apiaryMaps) {
      const apiKey = this.CONFIG.VUE_APP_API_KEY_GOOGLE_MAPS;
      const latitude = apiaryMaps.latitude;
      const longitude = apiaryMaps.longitude;
      const zoom = 14;
      const size = "800x600";

      const markers = this.$refs.myMap.getMarkers().observations || [];
      4;

      const mainMarker = `markers=color:red|label:P|${latitude},${longitude}`;

      const markersParam = markers
        .map(
          (marker) =>
            `markers=color:blue|label:${marker.name[0].toUpperCase()}|${
              marker.latitude
            },${marker.longitude}`
        )
        .join("&");

      return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=${size}&maptype=roadmap&${mainMarker}&${markersParam}&key=${apiKey}`;
    },

    openDialogDetails(item) {
      this.selectedItem = item;
      this.dialogDetails = true;
    },

    closeDialogDetails() {
      this.dialogDetails = false;
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },

    importTo() {
      this.dialogImport = true;
    },

    async importDataToProject() {
      if (!this.allApiariesTable.selected) {
        return;
      }
      try {
        let data = [];

        this.allApiariesTable.selected.forEach((item) => {
          data.push(item.id);
        });

        await this.minamelServices
          .ApiaryServiceV2()
          .importToProject(this.projectId, data);

        this.snackbar.color = "success";
        this.snackbar.message = "Dados importados com sucesso";
        this.$root.$SnackBar.show(this.snackbar);

        this.allApiariesTable.selected = [];

        this.allApiariesTable.pagination.page = 1;

        this.getAllApiaries();
        this.getData();

        // bus.$emit("refreshBeeSwarmCaptureList");
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message =
          "Ocorreu um erro ao importar os dados para o projeto";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    handleSelect(item) {
      item.checked = !item.checked;

      if (!item.checked) {
        const index = this.allApiariesTable.selected.findIndex(
          (obj) => obj.id === item.id
        );

        this.allApiariesTable.selected.splice(index, 1);

        return;
      }

      this.allApiariesTable.selected.push(item);
    },
    // async handleFileUpload(event) {
    //   const file = event.target.files[0];

    //   if (file) {
    //     const data = await this.readExcelFile(file);
    //     const producers = this.mapExcelToProducers(data);

    //     producers.forEach(async (producer) => {
    //       await this.save(producer);
    //       this.$emit("addItemOnEnd", producer);
    //     });
    //   }
    // },

    // async readExcelFile(file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //       const arrayBuffer = event.target.result;
    //       const workbook = XLSX.read(arrayBuffer, { type: "array" });
    //       const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    //       const data = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
    //       resolve(data);
    //     };
    //     reader.onerror = (error) => reject(error);
    //     reader.readAsArrayBuffer(file);
    //   });
    // },
    // mapExcelToProducers(data) {
    //   const headers = data[0];
    //   const rows = data.slice(1);

    //   return rows.map((row) => {
    //     return {
    //       nome: row[headers.indexOf("Nome")],
    //       cpf: row[headers.indexOf("CPF")],
    //       telefone: row[headers.indexOf("Telefone")],
    //       email: row[headers.indexOf("Email")],
    //       endereco: row[headers.indexOf("Endereço")],
    //     };
    //   });
    // },
  },

  watch: {
    "$route.query.apiaryGroup": {
      immediate: true,
      handler(newValue) {
        this.filter.apiaryGroup = newValue ?? null;
        this.getData();
      },
    },

    "table.pagination.page": function () {
      this.getData();
    },

    "allApiariesTable.pagination.page": function () {
      this.getAllApiaries();
    },

    dialogImport: function () {
      if (this.dialogImport) {
        this.getAllApiaries();
      }
    },
  },
};
</script>

<style>
.coord {
  display: flex;
  gap: 10px;
}
</style>
