<template>
  <div>
    <v-card
      style="
        position: absolute;
        top: 150px;
        z-index: 9999;
        width: 3px 100%;
        left: 10px;
        padding: 2px;
      "
    >
      <v-row row>
        <v-col xs="12">
          <v-list-tile avatar>
            <v-list-tile-content class="d-flex flex-row py-2 px-4">
              <input
                style="outline: none; height: 40px; width: 100%"
                placeholder="Pesquisar endereço"
                type="text"
                v-model="searchInput"
                @keyup.enter="searchInputLocation()"
              />
              <v-btn
                @click="searchInputLocation()"
                icon
                ripple
                id="search-input-btn"
              >
                <v-icon color="grey">search</v-icon>
              </v-btn>
            </v-list-tile-content>
          </v-list-tile>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      style="
        position: absolute;
        top: 225px;
        z-index: 9999;
        width: 3px 100%;
        left: 10px;
        padding: 10px;
      "
    >
      <v-select
        v-model="selectedRisks"
        clearable
        :items="risks"
        item-value="id"
        item-text="name"
        label="Risco"
        class="custom-label"
        multiple
        return-object
        dense
        outlined
        style="margin-bottom: -20px"
      ></v-select>
    </v-card>
    <!-- <template v-slot:selection="{ item }">
    <v-chip>
      <span>{{ item.name }}</span>
    </v-chip>
  </template> -->
    <v-card
      style="
        position: absolute;
        top: 300px;
        z-index: 9999;
        width: 3px 100%;
        left: 10px;
        padding: 2px;
      "
    >
      <v-btn color="primary" @click="openDescriptionModal">Observação</v-btn>
    </v-card>
    <v-dialog v-model="descriptionModal" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Adicionar Observação</span>
        </v-card-title>
        <v-card-text>
          <wysiwyg class="observation" v-model="description" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDescriptionModal"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveDescription"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- opções -->
    <v-dialog
      v-model="dialogMapOptions"
      hide-overlay
      max-width="600"
      min-width="300"
      max-height="600"
      min-height="300"
    >
      <v-card>
        <v-toolbar
          style="position: absolute; top: 0; z-index: 9999"
          dark
          color="primary"
        >
          <v-btn icon dark @click="dialogMapOptions = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Opções de mapa</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text style="padding-top: 80px">
          <v-row row wrap>
            <v-col
              xs="6"
              sm4
              md="2"
              align-center
              justify-center
              row
              wrap
              v-if="apiary && apiary.supplier_id"
            >
              <v-row align-center justify-center wrap>
                <v-card
                  @click="toggleExternalApiary('apic')"
                  width="43"
                  :color="apicApiary ? $vuetify.theme.primary : 'white'"
                  style="margin: 10px; cursor: pointer"
                >
                  <v-btn
                    icon
                    dark
                    small
                    style="width: 25px; height: 30px; cursor: pointer"
                  >
                    <v-icon :color="apicApiary ? 'white' : '#707070'">
                      emoji_nature
                    </v-icon>
                  </v-btn>
                </v-card>
                <span style="text-align: center">Apiários do produtor</span>
              </v-row>
            </v-col>

            <v-col xs="6" sm4 md="2" align-center justify-center row wrap>
              <v-row align-center justify-center wrap>
                <v-card
                  @click="toggleExternalApiary('all')"
                  width="43"
                  :color="allApiary ? $vuetify.theme.primary : 'white'"
                  style="margin: 10px; cursor: pointer"
                >
                  <v-btn
                    icon
                    dark
                    small
                    style="width: 25px; height: 30px; cursor: pointer"
                  >
                    <v-icon :color="allApiary ? 'white' : '#707070'">
                      nature
                    </v-icon>
                  </v-btn>
                </v-card>
                <span style="text-align: center">Todos os apiários</span>
              </v-row>
            </v-col>

            <v-col
              xs="6"
              sm4
              md="2"
              align-center
              justify-center
              row
              wrap
              v-if="apiary && apiary.apiary_group_id"
            >
              <v-row align-center justify-center wrap>
                <v-card
                  @click="toggleExternalApiary('group')"
                  width="43"
                  :color="groupApiary ? $vuetify.theme.primary : 'white'"
                  style="margin: 10px; cursor: pointer"
                >
                  <v-btn
                    icon
                    dark
                    small
                    style="width: 25px; height: 30px; cursor: pointer"
                  >
                    <v-icon :color="groupApiary ? 'white' : '#707070'"
                      >nature_people</v-icon
                    >
                  </v-btn>
                </v-card>
                <span style="text-align: center">Apiários do grupo</span>
              </v-row>
            </v-col>

            <v-col xs="6" sm4 md="2" align-center justify-center row wrap>
              <v-row align-center justify-center wrap>
                <v-card
                  @click="updateApiaryLocationInput"
                  width="43"
                  style="margin: 10px; cursor: pointer"
                >
                  <v-btn
                    icon
                    dark
                    small
                    style="width: 25px; height: 30px; cursor: pointer"
                  >
                    <v-icon color="red">add_location_alt</v-icon>
                  </v-btn>
                </v-card>
                <span style="text-align: center">
                  Informar Latitude/Longitude
                </span>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card
      @click="dialogMapOptions = true"
      :color="dialogMapOptions ? $vuetify.theme.primary : 'white'"
      style="position: absolute; bottom: 200px; right: 10px"
    >
      <v-btn icon dark small style="width: 25px; height: 30px; cursor: pointer">
        <v-icon :color="dialogMapOptions ? 'white' : '#707070'"
          >more_vert</v-icon
        >
      </v-btn>
    </v-card>

    <v-card
      @click="locationExternalMap"
      style="position: absolute; bottom: 265px; right: 10px"
    >
      <v-btn icon dark small style="width: 25px; height: 30px; cursor: pointer">
        <v-icon color="#707070">3d_rotation</v-icon>
      </v-btn>
    </v-card>

    <v-card
      @click="addRuleActive"
      :color="ruleActive ? $vuetify.theme.primary : 'white'"
      style="position: absolute; bottom: 330px; right: 10px"
    >
      <v-btn icon dark small style="width: 25px; height: 30px; cursor: pointer">
        <v-icon :color="ruleActive ? 'white' : '#707070'">square_foot</v-icon>
      </v-btn>
    </v-card>

    <!-- fim opções -->
    <v-card>
      <v-expansion-panels>
        <v-expansion-panel
          style="
            position: absolute;
            bottom: 0px !important;
            width: 60%;
            min-width: 247px;
            max-height: 300px;
            left: 0px !important;
            overflow-y: auto;
            max-height: 300px;
          "
        >
          <v-expansion-panel-header>
            <div v-if="marker">
              <v-row wrap row>
                <span
                  style="display: flex; align-items: center"
                  class="ml-8"
                  small
                  color="white"
                >
                  <strong
                    >{{ marker.getTitle() }} {{ getRemarker(marker) }}
                  </strong>
                </span>
                <v-tooltip transition="fab-transition" top>
                  <v-chip
                    slot="activator"
                    small
                    color="#0ebcff"
                    text-color="white"
                    >{{
                      minamelServices
                        .LatLngService()
                        .checkLatLngType(marker.getPosition().lat(), {
                          typeLatLng: "lat",
                        })
                        ? minamelServices
                            .LatLngService()
                            .checkLatLngType(marker.getPosition().lat(), {
                              typeLatLng: "lat",
                            }).newValue
                        : marker.getPosition().lat()
                    }}</v-chip
                  >
                  <span>
                    <ul
                      v-for="lat in minamelServices
                        .LatLngService()
                        .checkLatLngType(marker.getPosition().lat(), {
                          typeLatLng: 'lat',
                        }).extra"
                      :key="lat.type"
                    >
                      <li>{{ `${lat.type}: ${lat.newValue}` }}</li>
                    </ul>
                  </span>
                </v-tooltip>
                <v-tooltip transition="fab-transition" top>
                  <v-chip
                    class="mx-2"
                    slot="activator"
                    small
                    color="#d4a041"
                    text-color="white"
                    >{{
                      minamelServices
                        .LatLngService()
                        .checkLatLngType(marker.getPosition().lng(), {
                          typeLatLng: "lng",
                        })
                        ? minamelServices
                            .LatLngService()
                            .checkLatLngType(marker.getPosition().lng(), {
                              typeLatLng: "lng",
                            }).newValue
                        : marker.getPosition().lng()
                    }}</v-chip
                  >
                  <span>
                    <ul
                      v-for="lng in minamelServices
                        .LatLngService()
                        .checkLatLngType(marker.getPosition().lng(), {
                          typeLatLng: 'lng',
                        }).extra"
                      :key="lng.type"
                    >
                      <li>{{ `${lng.type}: ${lng.newValue}` }}</li>
                    </ul>
                  </span>
                </v-tooltip>
                <!-- <v-chip small color="#0ebcff" text-color="white" @click.native.stop>{{minamelServices.LatLngService().checkLatLngType(marker.getPosition().lat(), { typeLatLng: 'lat' }).newValue}}</v-chip>
							<v-chip small color="#d4a041" text-color="white" @click.native.stop>{{minamelServices.LatLngService().checkLatLngType(marker.getPosition().lng(), { typeLatLng: 'lng' }).newValue}}</v-chip>-->
              </v-row>
            </div>
            <div v-else>Legenda</div>
          </v-expansion-panel-header>
          <v-divider></v-divider>
          <v-expansion-panel-content>
            <v-row row>
              <v-col xs="12" md="12" v-if="observation_list.length > 0">
                <v-list-tile
                  v-for="remark in observation_list"
                  :key="remark.id"
                  avatar
                >
                  <v-list-tile-avatar>
                    <v-icon color="yellow" class="grey lighten-1 white--text"
                      >mode_comment</v-icon
                    >
                  </v-list-tile-avatar>

                  <v-list-tile-content>
                    <v-list-tile-title
                      ><b>{{ remark.getTitle() }}</b>
                      {{ remark.position }}</v-list-tile-title
                    >
                    <!-- <v-list-tile-sub-title v-html="item.subtitle"></v-list-tile-sub-title> -->
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn
                      @click="editObservation(remark, remark.latLng)"
                      icon
                      ripple
                    >
                      <v-icon color="grey">create</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
              </v-col>
              <v-col v-else xs="12" md="12" class="d-flex flex-column">
                <v-list-tile>
                  <v-list-tile-avatar>
                    <v-btn
                      large
                      rounded
                      icon
                      style="background-color: lightgrey"
                      elevation="1"
                      class="mx-2 my-2"
                    >
                      <v-icon color="red" class="white--text">
                        location_on
                      </v-icon>
                    </v-btn>
                  </v-list-tile-avatar>

                  <v-list-tile-content>
                    <v-list-tile-title>
                      Clique em qualquer lugar do mapa para adicionar uma
                      localização de apiário.
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>

                <v-divider />

                <v-list-tile>
                  <v-list-tile-avatar>
                    <v-btn
                      large
                      rounded
                      icon
                      style="background-color: lightgrey"
                      elevation="1"
                      class="mx-2 my-2"
                    >
                      <v-icon color="yellow" class="white--text">
                        mode_comment
                      </v-icon>
                    </v-btn>
                  </v-list-tile-avatar>

                  <v-list-tile-content>
                    <v-list-tile-title
                      >Clique em uma area circular de apiário para adicionar uma
                      observação.</v-list-tile-title
                    >
                  </v-list-tile-content>
                </v-list-tile>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <observation-form-dialog
      ref="observation_form"
      :active="observation_form_controller.show"
      :controller="observation_form_controller"
      :blocked="mapBlocked"
      @save="saveObservation"
      @edit="editObservation"
      @close="closeObservationDialog"
      @destroy="destroyObservation"
    />
  </div>
</template>
<script>
import { bus } from "@/main";
import DeleteIcon from "./icons/delete_svg.png";
import markerIcon from "./icons/marker_svg.png";
import externalMarkerIcon from "./icons/external_marker_svg.png";
import MeasureTool from "measuretool-googlemaps-v3";
import ObservationFormDialog from "./marker/ObservationFormDialog.vue";

export default {
  components: { ObservationFormDialog },
  props: {
    mapBlocked: Boolean,
  },
  data() {
    return {
      searchInput: null,
      descriptionModal: false,
      data_apiary: null,
      marker: null,
      old_circles: [],
      observation_list: [],
      observations: [],
      selectedRisks: [],
      fields: [],
      apiary: null,
      circles: null,
      google: null,
      map: null,
      dialogMapOptions: false,
      ruleActive: false,
      measureTool: null,
      apicApiary: false,
      description: null,
      groupApiary: false,
      allApiary: false,
      externalMarkerList: [],
      handle_marker: {},
      observation_form_controller: {
        show: false,
        map: {},
        position: {},
        google_tools: {},
        observation: {},
        marker: null,
      },
      risks: [
        { id: 1, name: "Baixo" },
        { id: 2, name: "Médio" },
        { id: 3, name: "Alto" },
        { id: 4, name: "Indeferido" },
        { id: 5, name: "Não Há" },
      ],
    };
  },
  watch: {
    apiary(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.description = null;
        this.selectedRisks = null;
      }
    },
  },
  methods: {
    getRemarker() {},
    getFields() {
      if (this.selectedRisks || this.description) {
        this.fields = {
          description: this.description,
          risks: this.selectedRisks
            .filter((risk) => {
              return risk !== undefined;
            })
            .map((risk) => ({ name: risk.name })),
        };
      }

      return this.fields;
    },
    getMarkers() {
console.log('this.observation_list: ', this.observation_list);

      let observation_list = this.observation_list.map((remark) => {
        let remark_obj = {
          latitude: remark.getPosition().lat(),
          longitude: remark.getPosition().lng(),
          name: remark.name,
          description: remark.description,
        };
        return remark_obj;
      });

      let circles = this.circles

      let newMarker = this.marker;

      return { marker: newMarker, observations: observation_list, circles };
    },

    openDescriptionModal() {
      this.descriptionModal = true;
    },
    closeDescriptionModal() {
      this.descriptionModal = false;
    },
    saveDescription() {
      this.closeDescriptionModal();
    },
    clearMap() {
      if (this.marker) {
        this.marker.setMap(null);
      }
      if (this.old_circles.length) {
        this.old_circles.map((circle) => {
          circle.setMap(null);
        });
      }
      if (this.observation_list.length) {
        this.observation_list.map((remark) => {
          remark.setMap(null);
        });
      }

      // this.circles.map(circle => { circle.setMap(null) });
      if (this.externalMarkerList.length) {
        this.externalMarkerList.map((marker) => {
          marker.circles.map((circle) => {
            circle.setMap(null);
            return circle;
          });
          marker.setMap(null);
          return marker;
        });
      }
      if (this.ruleActive) {
        this.measureTool.end();
        this.ruleActive = false;
        document.getElementById("map").style.filter = null;
      }
    },
    async updateApiaryLocationInput() {
      let oldLat = this.marker
        ? this.minamelServices
            .LatLngService()
            .checkLatLngType(this.marker.getPosition().lat(), {
              typeLatLng: "lat",
            }).newValue
        : "";
      let oldLng = this.marker
        ? this.minamelServices
            .LatLngService()
            .checkLatLngType(this.marker.getPosition().lng(), {
              typeLatLng: "lng",
            }).newValue
        : "";

      let new_position = await this.alertInputLocation(oldLat, oldLng);

      if (new_position.value) {
        new_position = new this.google.maps.LatLng(
          new_position.value.lat,
          new_position.value.lng
        );
        if (this.marker) {
          this.marker.setPosition(new_position);
          this.old_circles.map((circle) => {
            circle.setCenter(new_position);
          });

          let alertConfirm = await this.alertConfirm(
            `Deseja atualizar a localização do apiário "${this.marker.getTitle()}"?`
          );

          if (alertConfirm) {
            this.marker.lastPosition = new_position;
            this.map.setCenter(new_position);
            // this.updateApiaryLocation(new_position);
          } else {
            this.marker.setPosition(this.marker.lastPosition);
            this.map.setCenter(this.marker.lastPosition);
            this.old_circles.map((circle) => {
              circle.setCenter(this.marker.lastPosition);
            });
          }
        } else {
          // this.old_circles.map(circle => { circle.setCenter(new_position) });
          // this.data_apiary.latLng = new_position;
          // this.addMainMarker(this.google, this.map, this.data_apiary);
          // this.updateApiaryLocation(new_position);

          let alertConfirm = await this.alertConfirm(
            `Deseja atualizar a localização do apiário?`
          );
          if (alertConfirm) {
            this.old_circles.map((circle) => {
              circle.setCenter(new_position);
            });
            this.data_apiary.latLng = new_position;
            this.addMainMarker(this.google, this.map, this.data_apiary);
            this.map.setCenter(new_position);
          }
        }
      }
    },
    toggleExternalApiary(type) {
      if (type === "group") {
        if (!this.groupApiary) {
          this.groupApiary = true;
          this.apicApiary = false;
          this.allApiary = false;

          this.removeAllExternalMarker();
          if (this.apiary.apiary_group_id) {
            this.getExternalMarkers({
              apiary_group_id: this.apiary.apiary_group_id,
            });
            return;
          }
          this.alertError({
            title: "Este apiário não possui um grupo atrelado",
            footer: null,
          });
          this.apicApiary = false;

          return;
        }
        this.groupApiary = false;
        this.removeAllExternalMarker();
      }
      if (type === "apic") {
        if (!this.apicApiary) {
          this.apicApiary = true;
          this.allApiary = false;
          this.groupApiary = false;

          this.removeAllExternalMarker();

          if (this.apiary.supplier_id) {
            this.getExternalMarkers({
              supplier_id: this.apiary.supplier_id,
            });
            return;
          }
          this.alertError({
            title: "Este apiário não possui um produtor atrelado",
            footer: null,
          });
          this.apicApiary = false;

          return;
        }
        this.apicApiary = false;
        this.removeAllExternalMarker();
      }

      if (type === "all") {
        if (!this.allApiary) {
          this.allApiary = true;
          this.apicApiary = false;
          this.groupApiary = false;

          this.removeAllExternalMarker();

          this.getExternalMarkers();
          return;
        }
        this.allApiary = false;
        this.removeAllExternalMarker();
      }
    },
    checkRuleActive() {
      return this.ruleActive;
    },
    addRuleActive() {
      if (!this.ruleActive) {
        if (this.marker && this.old_circles.length) {
          this.marker.setOptions({
            clickableIcons: false,
            draggable: false,
          });
          this.measureTool.addListener("measure_start", () => {
            this.old_circles.map((circle) => {
              circle.setOptions({ clickable: false });
              return circle;
            });
            this.externalMarkerList.map((marker) => {
              marker.circles.map((circle) => {
                circle.setOptions({ clickable: false });
                return circle;
              });
              return marker;
            });
          });

          this.measureTool.addListener("measure_end", () => {
            this.old_circles.map((circle) => {
              circle.setOptions({ clickable: true });
              return circle;
            });
            this.externalMarkerList.map((marker) => {
              marker.circles.map((circle) => {
                circle.setOptions({ clickable: false });
                return circle;
              });
              return marker;
            });
          });

          if (this.observation_list.length) {
            this.observation_list.map((remark) => {
              remark.setOptions({
                clickableIcons: false,
                draggable: false,
              });
              return remark;
            });
          }
        }
        this.map.setOptions({ draggableCursor: "crosshair" });
        this.measureTool.start();
        this.ruleActive = true;
        document.getElementById("map").style.filter = "contrast(0.7)";
        this.map.setMapTypeId("roadmap");
      } else {
        if (this.marker && this.old_circles.length) {
          this.marker.setOptions({
            clickableIcons: true,
            draggable: true,
          });

          if (this.observation_list.length) {
            this.observation_list.map((remark) => {
              remark.setOptions({
                clickableIcons: true,
                draggable: true,
              });
              return remark;
            });
          }
        }
        // this.map.setOptions({clickableIcons: true});

        this.measureTool.end();
        this.ruleActive = false;
        document.getElementById("map").style.filter = null;
      }
    },
    distanceTwoPoints(p3, p4) {
      let distance = this.google.maps.geometry.spherical.computeDistanceBetween(
        p3,
        p4
      );
      // let distance = 10;
      return (distance / 1000).toFixed(2).toString().replace(".", ","); // dividing by 1000 to get Kilometers
    },
    locationExternalMap() {
      let position = this.map.getCenter();
      window.open(
        `https://maps.google.com/maps?t=k&q=${position.lat()},${position.lng()}`
      );
    },
    async searchInputLocation() {
      console.log("this.description: ", this.description);
      await this.geocoder.geocode(
        { address: this.searchInput },
        async (results, status) => {
          if (status === "OK") {
            let places = results[0].geometry.location;
            this.map.setCenter(places);
            if (this.marker) {
              this.marker.setPosition(places);
              this.old_circles.map((circle) => {
                circle.setCenter(places);
              });

              let alertConfirm = await this.alertConfirm(
                `Deseja atualizar a localização do apiário "${this.marker.getTitle()}"?`
              );

              if (alertConfirm) {
                this.marker.lastPosition = places;
                this.map.setCenter(places);
                this.updateApiaryLocation(places);
              } else {
                this.marker.setPosition(this.marker.lastPosition);
                this.map.setCenter(this.marker.lastPosition);
                this.old_circles.map((circle) => {
                  circle.setCenter(this.marker.lastPosition);
                });
              }
            } else {
              this.old_circles.map((circle) => {
                circle.setCenter(places);
              });
              this.data_apiary.latLng = places;
              this.addMainMarker(this.google, this.map, this.data_apiary);
              // this.updateApiaryLocation(places);
            }
          } else {
            this.alertError({
              title: "Sem resultados",
              text: "Não foi possível encontrar resultados, verifique as informações e tente novamente.",
              footer: null,
            });
          }
        }
      );
    },
    async loadChildMarkers(apiary, observations, circles, google, map) {
      this.searchInput = null;
      this.google = google;
      this.map = map;
      this.measureTool = new MeasureTool(this.map, {
        contextMenu: false,
      });

      this.geocoder = new this.google.maps.Geocoder();

      this.marker = null;
      this.data_apiary = null;
      this.old_circles = [];
      this.observation_list = [];
      this.apiary = apiary;
      this.observations = observations;
      this.circles = circles;
      this.data_apiary = this.apiary;
      this.ruleActive = false;
      this.apicApiary = false;
      this.groupApiary = false;
      this.allApiary = false;
      this.externalMarkerList = [];

      if (this.data_apiary.latLng) {
        await this.addMainMarker(this.google, this.map, this.data_apiary);
      }

      if (this.observations) {
        await this.loadApiaryObservations(this.observations);
      }

      if (this.data_apiary.map_observation) {
        this.description = this.data_apiary.map_observation;
      }
      if (this.data_apiary.risk) {
        this.selectedRisks = this.data_apiary.risk.split(", ").map((name) => {
          return this.risks.find((risk) => risk.name === name);
        });
      }

      if (!this.mapBlocked) {
        this.addOnClickEventOnMarker();
      }

      this.$root.$LoadingBar.hide();
    },
    addOnClickEventOnMarker() {
      this.google.maps.event.addListener(this.map, "click", async (event) => {
        if (this.checkRuleActive()) return;
        if (this.marker) {
          this.marker.setPosition(event.latLng);
          this.old_circles.map((circle) => {
            circle.setCenter(event.latLng);
          });

          let alertConfirm = await this.alertConfirm(
            `Deseja atualizar a localização do apiário "${this.marker.getTitle()}"?`
          );

          if (alertConfirm) {
            this.marker.lastPosition = event.latLng;
            this.map.setCenter(event.latLng);
            // this.updateApiaryLocation(event.latLng);
          } else {
            this.marker.setPosition(this.marker.lastPosition);
            this.map.setCenter(this.marker.lastPosition);
            this.old_circles.map((circle) => {
              circle.setCenter(this.marker.lastPosition);
            });
          }
        } else {
          this.old_circles.map((circle) => {
            circle.setCenter(event.latLng);
          });
          this.data_apiary.latLng = event.latLng;
          this.addMainMarker(this.google, this.map, this.data_apiary);
          // this.updateApiaryLocation(event.latLng);
        }
      });
    },
    async getExternalMarkers(search = {}) {
      let response = await this.minamelServices
        .ApiaryService()
        .getApiary()
        .search(search);
      try {
        if (response.data.length > 0) {
          response.data.map(async (apiary) => {
            if (apiary.id === this.apiary.id) return apiary;
            apiary.latLng = {
              lat: parseFloat(apiary.latitude),
              lng: parseFloat(apiary.longitude),
            };
            let externalMarker = await this.addExternalMarker(
              this.google,
              this.map,
              apiary
            );
            this.externalMarkerList.push(externalMarker);
            return apiary;
          });
        } else if (response.error) {
          this.alertError({ text: response.message });
        }
      } catch (error) {
        this.alertError({ text: error });
      }
    },
    async deleteApiaryRemark(remark) {
      // eslint-disable-next-line no-return-await
      return await this.minamelServices
        .ApiaryRemarkService()
        .deleteApiaryRemark()
        .destroy({ id: remark.id })
        .then((response) => {
          if (!response.error && response.removeId) {
            return response.removeId;
          } else {
            this.alertError({ text: response.message });
          }
        })
        .catch((error) => {
          this.alertError({ text: error });
        });
    },
    updateRemarkTitle(remark) {
      let title = [];
      if (remark.distanceToMainMarker) {
        title.push(`${remark.distanceToMainMarker} km`);
        if (remark.description) {
          title.push(remark.description);
        }
      }
      remark.setTitle(title.join(" - "));
    },
    /**
     *
     * OBSERVATIONS
     *
     */
    openObservationDialog(google, map, position, observation = {}) {
      this.observation_form_controller.map = map;
      this.observation_form_controller.google_tools = google;
      this.observation_form_controller.position = position;
      this.observation_form_controller.observation = observation;
      this.observation_form_controller.marker = this.marker;
      this.observation_form_controller.show = true;
    },

    closeObservationDialog() {
      this.observation_form_controller.show = false;
      this.observation_form_controller.observation = {};
    },

    editObservation(observation, event) {
      this.observation_form_controller.show = true;

      observation.position = event.latLng;

      this.observation_form_controller.observation = observation;
    },

    saveObservation(observation, referUpdating, setup = false) {
      const found = this.findObservation(observation);

      if (this.checkRuleActive()) return;

      if (found.result && found.index != null) {
        this.observation_list[found.index] = observation;
      } else {
        observation.index = this.observation_list.length + 1;
        this.observation_list.push(observation);
      }

      if (referUpdating && !setup) {
        bus.$emit("save_observation");
      }
    },

    destroyObservation(observation) {
      this.observation_list.forEach((added) => {
        if (added.index == observation.index) {
          added.setMap(null);
        }
      });

      this.observation_list = this.observation_list.filter(
        (added) => added.index != observation.index
      );
      bus.$emit("save_observation");
    },

    loadApiaryObservations(observations) {
      observations.forEach((observation) => {
        this.$nextTick(() => {
          this.observation_form_controller.map = this.map;
          this.observation_form_controller.google_tools = this.google;
          this.observation_form_controller.observation = observation;
          this.observation_form_controller.marker = this.marker;

          const position = new this.google.maps.LatLng(
            observation.latitude,
            observation.longitude
          );
          this.observation_form_controller.position = position;
          this.$refs.observation_form.create(observation, true, true);
        });
      });
    },

    findObservation(observation) {
      let foundIndex = null;

      const result = this.observation_list.find((added, index) => {
        foundIndex = index;

        return (
          added.position.lat == observation.position.lat &&
          added.position.lng == observation.position.lng
        );
      });

      return { result, index: foundIndex };
    },

    async addExternalMarker(google, map, apiary) {
      const name = !apiary || !apiary.name ? "Novo Apiário" : apiary.name;
      const supplier =
        !apiary || !apiary.supplier_name ? "" : apiary.supplier_name;
      const title = name + " - " + supplier;

      let markerOptions = {
        draggable: false,
        icon: externalMarkerIcon,
        title,
        label: {
          text: title,
          className: "google_maps_main_marker",
        },
      };
      let externalMarker = await this.addMarker(
        google,
        map,
        apiary,
        markerOptions
      );
      externalMarker.circles = [];

      await this.circles.map(async (circle) => {
        let new_circle = await this.addCircle(google, map, circle, apiary);
        externalMarker.circles.push(new_circle);
        return circle;
      });

      return externalMarker;
    },
    async removeAllExternalMarker() {
      if (this.externalMarkerList.length) {
        await this.externalMarkerList.map(async (marker) => {
          marker.setMap(null);
          await marker.circles.map((circle) => {
            circle.setMap(null);
            return circle;
          });
          return marker;
        });
        this.externalMarkerList = [];
      }
    },
    async addMainMarker(google, map, apiary) {
      const name = !apiary || !apiary.name ? "Novo Apiário" : apiary.name;
      const supplier =
        !apiary || !apiary.supplier_name ? "" : apiary.supplier_name;
      const title = name + " - " + supplier;

      let markerOptions = {
        title,
        label: {
          text: title,
          className: "google_maps_main_marker",
        },
      };
      this.marker = await this.addMarker(google, map, apiary, markerOptions);
      if (!this.old_circles.length) {
        this.circles.map((circle) => {
          let new_circle = this.addCircle(google, map, circle, apiary);
          this.old_circles.push(new_circle);
          google.maps.event.addListener(new_circle, "click", (event) => {
            if (this.checkRuleActive()) return;
            this.openObservationDialog(google, map, event.latLng);
          });
        });
      }

      this.marker.lastPosition = this.marker.getPosition();

      if (!this.mapBlocked) {
        this.addDragEventOnMarker();
      }
    },
    addDragEventOnMarker() {
      this.marker.addListener("drag", (event) => {
        if (this.checkRuleActive()) return;
        // eslint-disable-next-line no-undef
        this.marker.setAnimation(google.maps.Animation.BOUNCE);
        this.old_circles.map((circle) => {
          circle.setCenter(event.latLng);
        });
      });

      this.marker.addListener("dragend", async (event) => {
        if (this.checkRuleActive()) return;
        this.marker.setAnimation(null);
        let alertConfirm = await this.alertConfirm(
          `Deseja atualizar a localização do apiário "${this.marker.getTitle()}"?`
        );

        if (alertConfirm) {
          this.marker.lastPosition = event.latLng;
          // eslint-disable-next-line no-undef
          map.setCenter(event.latLng);
          this.old_circles.map((circle) => {
            circle.setCenter(event.latLng);
          });
          // this.updateApiaryLocation(event.latLng);
        } else {
          this.marker.setPosition(this.marker.lastPosition);
          // eslint-disable-next-line no-undef
          map.setCenter(this.marker.lastPosition);
          this.old_circles.map((circle) => {
            circle.setCenter(this.marker.lastPosition);
          });
        }
      });
    },
    addMarker(google, map, apiary, markerOptions = {}) {
      const { Marker } = google.maps;
      let marker = new Marker({
        label: {
          text: `${apiary.name}`,
          className: "google_maps_main_marker",
        },
        draggable: !this.mapBlocked,
        position: apiary.latLng
          ? apiary.latLng
          : {
              lat: parseFloat(apiary.latitude),
              lng: parseFloat(apiary.longitude),
            },
        map,
        title: apiary.name,
        icon: markerIcon,
        ...markerOptions,
      });
      return marker;
    },
    addCircle(google, map, circle, apiary) {
      const { Circle } = google.maps;

      let new_circle = new Circle({
        strokeColor: circle.color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: circle.color,
        fillOpacity: 0,
        map,
        center: apiary.latLng,
        radius: circle.radius,
      });

      return new_circle;
    },
    alertError(error = {}) {
      this.$swal.fire({
        type: "error",
        title: "Ocorreu um erro",
        footer: "Em caso de duvidas, entre em contato com o suporte.",
        ...error,
      });
    },
    alertConfirm(ask, alertConfirmOptions = {}) {
      let returnAlert = this.$swal
        .fire({
          title: `${ask}`,
          type: "question",
          text: alertConfirmOptions.text ? alertConfirmOptions.text : null,
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return false;
          }
          return true;
        });
      return returnAlert;
    },
    async alertInputLocation(latitudeValue, longitudeValue) {
      let html = `<div style='display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 25px;'>
                        <label for='marker-location-lat'>Latitude: </label>
                        <input type='text' id='marker-location-lat' class='swal2-input' value='${latitudeValue.replace(
                          "'",
                          "&apos;"
                        )}'>
                        <label for='marker-location-lng'>Longitude: </label>
                        <input type='text' id='marker-location-lng' class='swal2-input' value='${longitudeValue.replace(
                          "'",
                          "&apos;"
                        )}'>
                        </div>`;
      let returnAlert = await this.$swal.fire({
        title: "Adicionar Latitude/Longitude",
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        html,
        focusConfirm: true,
        preConfirm: async () => {
          let latitude = document.getElementById("marker-location-lat");
          // await latitude.addEventListener("keyup", async () => {
          if (
            !this.minamelServices
              .LatLngService()
              .checkLatLngType(latitude.value, {
                typeLatLng: "lat",
              })
          ) {
            await this.alertError({
              title: `Informe uma latitude válida.`,
              footer: null,
            });
            return false;
          }
          // });

          let longitude = document.getElementById("marker-location-lng");
          // await longitude.addEventListener("keyup", async () => {
          if (
            !this.minamelServices
              .LatLngService()
              .checkLatLngType(longitude.value, {
                typeLatLng: "lng",
              })
          ) {
            await this.alertError({
              title: `Informe uma longitude válida.`,
              footer: null,
            });
            return false;
          }
          // });

          if (
            this.minamelServices
              .LatLngService()
              .checkLatLngType(latitude.value, {
                typeLatLng: "lat",
              }) &&
            this.minamelServices
              .LatLngService()
              .checkLatLngType(longitude.value, {
                typeLatLng: "lng",
              })
          ) {
            return {
              lat: parseFloat(
                this.minamelServices
                  .LatLngService()
                  .checkLatLngType(latitude.value, {
                    typeLatLng: "lat",
                  }).latLng
              ),
              lng: parseFloat(
                this.minamelServices
                  .LatLngService()
                  .checkLatLngType(longitude.value, {
                    typeLatLng: "lng",
                  }).latLng
              ),
            };
          }
        },
      });
      return returnAlert;
    },
    alertTextarea(alertTextareaOptions = {}) {
      let returnAlert = this.$swal
        .fire({
          type: "info",
          input: "text",
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: `<img style="margin: -5px !important;" src="${DeleteIcon}"/> <strong>Excluir</strong>`,
          allowOutsideClick: false,
          ...alertTextareaOptions,
        })
        .then(async (result) => {
          if (!result.dismiss) {
            return { exclude: false, value: result.value };
          }
          if (result.dismiss === "cancel") {
            let alertConfirmOptions = { type: `warning` };
            let exclude = await this.alertConfirm(
              `Você tem certeza que deseja excluir?`,
              alertConfirmOptions
            );
            return { exclude, value: result.value };
          }
        });
      return returnAlert;
    },
  },
};
</script>

<style>
.google_maps_main_marker {
  transform: translate(0px, -24px) !important;
  color: #f0ff00 !important;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 14px;
  text-align: left;
}

.custom-label {
  color: #9e9e9e !important; /* Cinza padrão */
}
</style>
