<template>
  <v-card style="padding: 12px">
    <!-- <v-card
		class="elevation-0"
		:class="!projectId ? 'ma-2' : 'ma-0 project-margin-adjustment'"
	> -->
    <v-form ref="form">
      <v-row row wrap>
        <!-- <v-col xs="12" md="12" pa-3>
					<span class="required grey--text"
						>* Preenchimento obrigatório.</span
					>
				</v-col> -->
        <!-- <v-col xs="12" md="4" pl-3 pa-3>
					<v-autocomplete
						:rules="[formRules.required]"
						:cache-items="true"
						chips
						:deletable-chips="true"
						v-model="register.supplier_id"
						:items="suppliers"
						item-text="name"
						item-value="id"
						class="required"
						label="Produtor"
						@change="onChangeSupplier()"
					>
						<template slot="item" slot-scope="data">
							{{ data.item.name }}
							<span class="font-weight-bold">
								- COD.
								{{ data.item.id }}</span
							>
						</template>
					</v-autocomplete>
				</v-col> -->
        <!-- <v-col xs="12" md="4" pa-3>
					<v-select
						:rules="[formRules.required]"
						v-model="register.beeswax_type"
						:items="beeswaxTypeOptions"
						label="Tipo de cera"
						class="required"
						disabled
					/>
				</v-col> -->

        <!-- <v-col xs="12" md="4" pa-3 v-show="canViewApiary()">
					<v-autocomplete
						:rules="[validateApiary]"
						chips
						:deletable-chips="true"
						v-model="register.apiary_import_key"
						:items="selectableApiaries"
						item-text="name"
						item-value="import_key"
						label="Apiário"
					/>
				</v-col> -->

        <!-- TODO: INSERIOR A COLUNA CORRETA DO COMPRADOR-->
        <v-col
          xs="12"
          md="4"
          pa-3
          pt-4
          v-if="register.movement_type === 'OUTPUT'"
        >
          <v-text-field
            precision="0"
            type="string"
            :rules="[formRules.required]"
            v-model="register.buyer"
            label="Comprador"
          />
        </v-col>

        <!-- TODO: SELECIONAR CORRETAMENTE O APIÁRIO -->
        <v-col xs="12" md="4" pa-3 v-if="register.movement_type === 'INPUT'">
          <v-autocomplete
            :rules="[validateApiary]"
            chips
            :deletable-chips="true"
            v-model="register.apiary_import_key"
            :items="mutableApiaries"
            item-text="name"
            item-value="import_key"
            label="Apiário"
          />
        </v-col>

        <v-col xs="12" md="4" pa-3 v-show="canViewExchangeCenter()">
          <v-select
            :rules="[validateExchangeCenter]"
            v-model="register.exchange_center_system_variable_id"
            :items="systemVariables"
            item-value="id"
            item-text="value"
            label="Centro de troca de cera"
            class="required"
          />
        </v-col>
        <v-col xs="12" md="4" pa-3 pt-4 class="mt-n1">
          <v-menu
            v-model="datePickerMenu"
            :close-on-content-click="false"
            full-width
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :rules="[formRules.required]"
                :value="$functions.formatDate(register.date)"
                clearable
                label="Data"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pt-br"
              v-model="register.date"
              @change="datePickerMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col xs="12" md="4" pa-3>
          <v-text-field
            :rules="[formRules.required]"
            v-model="register.quantity"
            type="number"
            label="Quantidade"
            suffix="KG"
            class="required"
          />
        </v-col>

        <v-col xs="12" md="4" pa-3>
          <v-select
            :rules="[formRules.required]"
            v-model="register.movement_type"
            :items="movementTypeOptions"
            label="Tipo de movimento"
            disabled
          />
        </v-col>

        <!-- <v-col xs="12" md="4" pa-3>
					<v-select :rules="[formRules.required]" v-model="register.type" label="Tipo" class="required" />
				</v-col>-->
      </v-row>

      <v-divider></v-divider>

      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn style="color: white" color="green" @click="save">Salvar</v-btn>
        <v-btn color="error" flat :to="`${routeName}/beeswax-appointments`"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import jwt from "vue-jwt-decode";
export default {
  name: "BeeswaxAppointmentsRegister",
  props: {
    apiaries: { type: Array },
    suppliers: { type: Array },
    projectId: { type: Number, default: null },
    routeName: { type: String, default: "/field-notes" },
  },
  data() {
    return {
      userData: null,
      formRules: {
        required: (value) => !!value || "Campo obrigatório",
        validApiary: () =>
          !!this.register.supplierId || "Selecione um produtor",
      },
      movementTypeOptions: [
        { value: "INPUT", text: "ENTRADA DE CERA" },
        { value: "OUTPUT", text: "SAÍDA DE CERA" },
      ],
      systemVariables: [],
      datePickerMenu: false,
      id: null,

      register: {},
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
      mutableApiaries: this.apiaries,
    };
  },
  async mounted() {
    this.userData = (await sessionStorage.token)
      ? jwt.decode(sessionStorage.token).userData
      : null;
    this.mutableApiaries = this.apiaries;

    this.getSystemVariables();

    this.id = this.projectId
      ? this.$route.params.childrenId
      : this.$route.params.id;
    if (this.id) {
      this.getData();
    }
  },
  computed: {
    selectableApiaries() {
      const filtered = this.apiaries.filter((obj) => {
        return this.register.supplier_id === obj.supplier_id;
      });

      if (filtered.length) {
        return filtered;
      }

      return [];
    },
  },
  methods: {
    /**
     * LOGICAS DE MERCADO DE TROCA DE CERA
     *
     * Caso seja do tipo coleta e cera bruta, o centro de troca não ficará disponível
     * Caso seja do tipo saída e cera bruta não haverá apiário mas haverá  centro de troca
     * Caso seja do tipo coleta e cera lâmina, o apiário não ficará disponível
     * Caso seja do tipo saída e a cera do tipo laminada o centro de troca não ficará disponível.
     *
     * os campos de quantidade no tipo laminado são substituidos
     * por campo de lamina e utiliza uma logica de conversao pra kg
     * cada lamina equivale a 185g
     */
    canViewApiary() {
      if (this.isToSend() && this.isLaminated()) {
        return true;
      }

      if (this.isToCollect() && this.isRaw()) {
        return true;
      }

      return false;
    },

    canViewExchangeCenter() {
      if (this.isToSend() && this.isRaw()) {
        return true;
      }

      if (this.isToCollect() && this.isLaminated()) {
        return true;
      }

      return false;
    },

    validateExchangeCenter(value) {
      if (!value && this.canViewExchangeCenter()) {
        return "Campo Obrigatório";
      }

      return null;
    },

    validateApiary(value) {
      if (!value && this.canViewApiary()) {
        return "Campo Obrigatório";
      }

      return null;
    },

    isLaminated() {
      return this.register.beeswax_type == "SHEET";
    },

    isRaw() {
      return this.register.beeswax_type == "BLOCK";
    },

    isToSend() {
      return this.register.movement_type == "OUTPUT";
    },

    isToCollect() {
      return this.register.movement_type == "INPUT";
    },

    convertKgToLaminatedQuantity() {
      let laminaWeight = 0.185;
      let result = this.register.quantity / laminaWeight;

      let serializedResult = Number(result).toFixed(0);

      return Number(serializedResult);
    },

    async getData() {
      const response = await this.minamelServices
        .BeeswaxAppointmentService()
        .show(this.id);

      this.register = response.data;
      this.register.date = this.register.date.split("T")[0];
    },

    async getSystemVariables() {
      const params = { type: "BEESWAX_APPOINTMENT_EXCHANGE_CENTER" };

      const response = await this.minamelServices
        .SystemVariableService()
        .get(params);

      this.systemVariables = response.data;
    },

    async save() {
      let formValidate = this.$refs.form.validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);
        return;
      }

      if (this.register.id) {
        this.update();
        return;
      }

      this.create();
    },

    onChangeSupplier() {
      if (!this.register.supplier_id) {
        this.mutableApiaries = this.apiaries;
        this.register.apiary_import_key = null;
        return;
      }

      this.mutableApiaries = [];
    },

    async create() {
      this.register.user_id = this.userData.id;
      this.register.type = "RECORD";
      this.register.quantity = parseFloat(this.register.quantity);

      try {
        const response = await this.minamelServices
          .BeeswaxAppointmentService()
          .create(this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Registro de cera incluído com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push(`${this.routeName}/beeswax-appointments`);
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const response = await this.minamelServices
          .BeeswaxAppointmentService()
          .update(this.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Registro de cera atualizado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.push(`${this.routeName}/beeswax-appointments`);
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao atualizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
};
</script>

<style>
.mt-n2 {
  margin-top: -8px;
}
</style>
